import React from 'react';

import { Box, Button, styled } from '@mui/material';

import { colors } from '@themes/config/theme-colors';

const Container = styled(Box)<{ theming?: 'dark' | 'yellow'; size: number; selected: boolean; disabled: boolean }>(({
  theming,
  size,
  selected,
  disabled,
}) => {
  const styles =
    theming === 'dark'
      ? {
          backgroundColor: 'black',
          borderRadius: '5px',
          outline: selected ? `3px solid ${colors.semantic.yellow}` : '3px solid black',
        }
      : {
          borderRadius: '2px',
          backgroundColor: selected ? '#FEC84B' : '#E9E9E9',
        };

  return {
    display: 'grid',
    gridTemplateColumns: `repeat(${size}, 1fr)`,
    gridTemplateRows: `repeat(${size}, 1fr)`,
    width: '60px',
    height: '60px',
    minWidth: '60px',
    minHeight: '60px',
    cursor: disabled ? 'default' : 'pointer',
    position: 'relative',
    '&:hover': {
      button: {
        visibility: 'visible',
      },
    },
    ...styles,
  };
});

export const LedPixelMatrixComponentPreview = ({
  matrix,
  onClick,
  onRemove,
  size,
  selected,
  theming = 'dark',
  disabled = false,
}: {
  size: number;
  matrix: boolean[];
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  onRemove?: () => void;
  selected: boolean;
  theming?: 'dark' | 'yellow';
  disabled?: boolean;
}) => {
  return (
    <Container
      size={size}
      onClick={e => !disabled && onClick(e)}
      selected={selected}
      theming={theming}
      disabled={disabled}
    >
      {matrix.map((value, index) => (
        <Box key={index} sx={{ backgroundColor: value ? 'white' : 'rgba(0,0,0,0)' }} />
      ))}
      {onRemove && !disabled && (
        <Button
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            width: '20px',
            height: '20px',
            display: 'block',
            visibility: 'hidden',
            padding: '0px',
            margin: '0px',
            minWidth: 'auto',
            transform: 'translate(50%, -50%)',
            zIndex: 1,
          }}
          color={'error'}
          variant="contained"
          size={'small'}
          onClick={e => {
            e.stopPropagation();
            onRemove();
          }}
        >
          -
        </Button>
      )}
    </Container>
  );
};
