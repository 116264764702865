import { BaseModule } from './base-module';

export class Linetracker extends BaseModule {
  constructor(id, client, store) {
    super(id, client, 'linetrackers', store);

    this.client.registerHandler(
      'onLinetracker',
      ({ data }) => {
        this.updateDataState({
          sensorData: {
            positions: Linetracker.parsePayload(data),
          },
        });
      },
      id
    );
  }

  static parsePayload(data) {
    const state = data[0].toString(2).padStart(3, '0').split('').reverse().join('');

    return {
      left: state[0] === '1',
      middle: state[1] === '1',
      right: state[2] === '1',
    };
  }
}
