import React from 'react';
import { styled, Button as MuiButton, ButtonProps } from '@mui/material';
import { Box } from '@mui/material';

interface CustomRoundIconButtonProps {
  icon: React.ReactNode;
  text?: React.ReactNode | string;
  mainColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}

export type RoundIconButtonProps = ButtonProps & CustomRoundIconButtonProps;

const StyledButton = styled(MuiButton, {
  shouldForwardProp: prop => !['mainColor', 'secondaryColor'].includes(prop as string),
})<{
  mainColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}>(({ mainColor, secondaryColor }) => ({
  textTransform: 'none',
  borderRadius: '50%',
  border: 'none',
  width: '42px',
  minWidth: '42px',
  height: '42px',
  minHeight: '42px',
  padding: '0',
  backgroundColor: secondaryColor,
  color: mainColor,
  '&:active': {
    backgroundColor: secondaryColor,
    color: mainColor,
  },
  '&:hover': {
    backgroundColor: secondaryColor,
    opacity: 0.8,
  },
  '&[disabled]': {
    opacity: 0.5,
    backgroundColor: secondaryColor,
  },
}));

const RoundToggleIconButton: React.FunctionComponent<RoundIconButtonProps> = ({
  icon,
  text,
  mainColor,
  secondaryColor,
  tertiaryColor,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
      }}
    >
      <StyledButton mainColor={mainColor} secondaryColor={secondaryColor} tertiaryColor={tertiaryColor} {...props}>
        {/* {icon} */}
        {React.cloneElement(icon as React.ReactElement, {
          htmlColor: props.value ? tertiaryColor : mainColor,
        })}
      </StyledButton>

      {text && (
        <Box
          sx={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '4px' }}
        >
          {text}
        </Box>
      )}
    </Box>
  );
};

export default RoundToggleIconButton;
