import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { RootState, useDispatch, useSelector } from '@store/configureStore';

import { RoboModel } from '@lib/robo/robo-model';
import { roboClientManager } from '@lib/robo/robo-client-manager';
import { RoboClient } from '@lib/robo/robo-client';

import { ModelStore, useModelStore } from '@webapp/hooks/use-model-store';

import { connect, disconnect, RoboState } from '@webapp/store/slices/devices/robo.slice';
import { FetchedRobo } from '@webapp/store/slices/devices/bridge.slice';
import { ModuleState, ModuleType } from '@webapp/store/types';
import { useAppSettings } from '@lib/hooks/use-app-settings';
import { AppSettingsIds } from '@constants/app-settings-ids';
import { ModuleId } from '@lib/robo/types';
import { selectConnectedModuleIdsFromModel } from '@webapp/store/slices/model/model.selectors';

type RoboContextType = RoboState & {
  macAddress: string | null;
  connect: ({ id, alias }: { id: string; alias: string }) => void;
  disconnect: () => void;
  client: RoboClient | null;
  model: RoboModel | null;
  store: ModelStore | null;
  connectedModules: ConnectedModule[];
};

export type ConnectedModule = {
  type: ModuleType;
  id: ModuleId;
  index: number;
};

export const RoboContext = createContext<RoboContextType>({
  id: '',
  name: '',
  alias: '',
  displayAlias: '',
  connected: false,
  batchCheckTimeout: null,
  macAddress: null,
  connect: () => undefined,
  disconnect: () => undefined,
  client: null,
  model: null,
  store: null,
  connectedModules: [],
});

export const RoboProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const robo = useSelector((state: RootState) => state.webapp.devices.robo);
  const bridge = useSelector((state: RootState) => state.webapp.devices.bridge);
  const model = useSelector((state: RootState) => state.webapp.model as ModuleState);

  const { getSettingValue } = useAppSettings();

  const roboFromBridge = bridge.roboList.find((r: FetchedRobo) => r.name === robo.name);

  const modelStore = useModelStore();

  const roboClient = useMemo(() => {
    return roboClientManager.getClient(robo.id);
  }, [robo.id]);

  const roboModel = useMemo(() => {
    if (roboClient) {
      return roboClient.getModel(modelStore.methods);
    }

    return null;
  }, [roboClient, modelStore.methods]);

  const batchCheckTimeoutRaw = getSettingValue(AppSettingsIds.BatchSensorsCheckTimeout);

  const connectRobo = useCallback(
    ({ id, alias }: { id: string; alias: string }) => {
      const timeout = typeof batchCheckTimeoutRaw === 'string' ? parseInt(batchCheckTimeoutRaw, 10) : NaN;

      dispatch(
        connect({
          id,
          alias,
          batchCheckTimeout: isNaN(timeout) ? null : timeout,
        })
      );
    },
    [dispatch]
  );
  const disconnectRobo = useCallback(() => dispatch(disconnect()), [dispatch]);

  const connectedModuleIds = selectConnectedModuleIdsFromModel(model);

  const connectedModules = useMemo<ConnectedModule[]>(() => {
    return connectedModuleIds.map(moduleId => {
      const { moduleType, modulePosition } = RoboModel.parseModuleId(moduleId);

      return {
        type: moduleType,
        id: moduleId,
        index: modulePosition,
      };
    });
  }, [connectedModuleIds]);

  return (
    <RoboContext.Provider
      value={{
        ...robo,
        macAddress: roboFromBridge?.macAddress ?? null,
        connect: connectRobo,
        disconnect: disconnectRobo,
        client: roboClient,
        model: roboModel,
        store: modelStore,
        connectedModules,
      }}
    >
      {children}
    </RoboContext.Provider>
  );
};
