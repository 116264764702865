import { useEffect, useRef, useState, useCallback } from 'react';
import { debounce } from 'lodash';

import { Box, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { ToolboxElementsContainer as ToolboxElementsContainer } from '@webapp/components/toolbox/styled/toolbox';
import ToolboxElement from '@webapp/components/toolbox/toolbox-element';

const arrowBoxStyles = {
  width: '40px',
  minWidth: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'white',
  cursor: 'pointer',
  color: '#5A418B',

  '&:hover': {
    color: '#5A418B',
    background: '#f0f0f0',
  },

  '&:active': {
    color: '#5A418B',
    background: '#fafafa',
  },
};

const ToolboxLeftArrow = ({ sx, ...props }) => {
  return (
    <Box
      role="button"
      aria-roledescription="Navigation Arrow"
      aria-label="Scroll Left"
      sx={{
        ...arrowBoxStyles,
        borderRight: '1px solid black',
        ...sx,
      }}
      {...props}
    >
      <KeyboardArrowLeftIcon
        sx={{
          width: '50px',
          height: '50px',
        }}
      />
    </Box>
  );
};

const ToolboxRightArrow = ({ sx, ...props }) => {
  return (
    <Box
      role="button"
      aria-roledescription="Navigation Arrow"
      aria-label="Scroll right"
      sx={{
        ...arrowBoxStyles,
        borderLeft: '1px solid black',
        ...sx,
      }}
      {...props}
    >
      <KeyboardArrowRightIcon
        sx={{
          width: '50px',
          height: '50px',
        }}
      />
    </Box>
  );
};

const ELEMENT_BUTTON_MARGIN = 10;
const SCROLL_PAGE_SIZE = 150;

export const ToolboxElements = ({ elementsToShow, elementHandlers, editor }) => {
  const containerRef = useRef();

  const calculateScrollState = useCallback(container => {
    const scrollLeft = container.scrollLeft;
    const scrollWidth = container.scrollWidth;
    const clientWidth = container.clientWidth;
    const hasScroll = scrollWidth > clientWidth;

    return {
      hasPrevButton: hasScroll && scrollLeft > ELEMENT_BUTTON_MARGIN,
      hasNextButton: hasScroll && scrollLeft + clientWidth < scrollWidth - ELEMENT_BUTTON_MARGIN,
    };
  }, []);

  const [showPreviousButton, setShowPreviousButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);

  const [isDrawn, setIsDrawn] = useState(false);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    const redrawArrows = debounce(() => {
      const { hasPrevButton, hasNextButton } = calculateScrollState(container);
      setShowPreviousButton(hasPrevButton);
      setShowNextButton(hasNextButton);
    }, 100);

    const resizeObserver = new ResizeObserver(entries => {
      const targetRect = entries?.[0]?.contentRect;
      if (!targetRect) {
        return;
      }

      redrawArrows();
    });

    resizeObserver.observe(container);

    container.addEventListener('scroll', redrawArrows);

    setIsDrawn(true);

    return () => {
      resizeObserver.disconnect();
      container.removeEventListener('scroll', redrawArrows);
    };
  }, [calculateScrollState]);

  const handleNavigate = direction => {
    const container = containerRef.current;
    const scrollAmount = SCROLL_PAGE_SIZE;

    if (direction === 'previous') {
      container.scrollLeft -= scrollAmount;
    } else if (direction === 'next') {
      container.scrollLeft += scrollAmount;
    }
  };

  // show empty toolbox if there are no elements to show
  if (elementsToShow.length === 0) {
    return (
      <ToolboxElementsContainer className="toolbox-elements-empty">
        <Typography variant="x-headline5-bold" sx={{ color: '#C7C7C7' }}>
          No available elements
        </Typography>
      </ToolboxElementsContainer>
    );
  }

  // show full toolbox with elements
  return (
    <ToolboxElementsContainer>
      <ToolboxLeftArrow
        onClick={() => {
          handleNavigate('previous');
        }}
        sx={{
          pointerEvents: showPreviousButton && isDrawn ? 'auto' : 'none',
          opacity: showPreviousButton && isDrawn ? 1 : 0,
          transition: 'opacity 0.2s',

          position: 'absolute',
          zIndex: 1000,
          height: '100%',
        }}
      />

      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          alignItems: 'center',

          overflowX: 'scroll',
          overflowY: 'hidden',
          scrollBehavior: 'smooth',

          width: '100%',
          flexGrow: 1,

          '&::-webkit-scrollbar': {
            display: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            display: 'none',
          },
          '::-webkit-scrollbar-track': {
            display: 'none',
          },
        }}
      >
        {elementsToShow.map(element => {
          return (
            <ToolboxElement
              key={element.id}
              element={element}
              handlers={elementHandlers[editor]}
              className={`ToolboxElement toolbox-element-${element.active ? 'active' : 'default'}`}
            />
          );
        })}
      </Box>

      <ToolboxRightArrow
        onClick={() => {
          handleNavigate('next');
        }}
        sx={{
          pointerEvents: showNextButton && isDrawn ? 'auto' : 'none',
          opacity: showNextButton && isDrawn ? 1 : 0,
          transition: 'opacity 0.2s',
          position: 'absolute',
          zIndex: 1000,
          height: '100%',
          right: 0,
        }}
      />
    </ToolboxElementsContainer>
  );
};
