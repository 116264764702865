import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LiveEditorState,
  AddWidgetPayload,
  RemoveWidgetPayload,
  UpdateWidgetPayload,
  UpdateWidgetDataPayload,
  UpdateWidgetPropsPayload,
  SetLiveEditorStatePayload,
} from '@webapp/store/types';

const initialState: LiveEditorState = {
  widgets: {},

  runtime: {
    isPlaying: false,

    playButtonIsBlocked: false,

    widgetsPlacement: {
      compact: true,
      startingColumn: 0,
    },
  },
};

const editorSlice = createSlice({
  name: 'live/editor',
  initialState,
  reducers: {
    setEditorState: (state, action: PayloadAction<SetLiveEditorStatePayload>) => {
      const { widgets } = action.payload;
      state.widgets = widgets ?? {};
    },

    resetEditorState: state => {
      state.widgets = {};
    },

    addWidget: (state, action: PayloadAction<AddWidgetPayload>) => {
      const { id, widget } = action.payload;
      state.widgets[id] = widget;
    },

    updateWidget: (state, action: PayloadAction<UpdateWidgetPayload>) => {
      const { id, widget } = action.payload;

      if (state.widgets[id]) {
        state.widgets[id] = { ...state.widgets[id], ...widget };
      }
    },

    removeWidget: (state, action: PayloadAction<RemoveWidgetPayload>) => {
      const { id } = action.payload;
      delete state.widgets[id];
    },

    removeAllWidgets: state => {
      Object.keys(state.widgets).forEach(id => {
        delete state.widgets[id];
      });
    },

    updateWidgetData: (state, action: PayloadAction<UpdateWidgetDataPayload>) => {
      const { id, data } = action.payload;
      const widget = state.widgets[id];

      if (widget) {
        widget.data = { ...widget.data, ...data };
      }
    },

    updateWidgetProps: (state, action: PayloadAction<UpdateWidgetPropsPayload>) => {
      const { id, props } = action.payload;
      const widget = state.widgets[id];

      if (widget) {
        widget.props = { ...widget.props, ...props };
      }
    },

    setWidgetsPlacement: (state, action: PayloadAction<LiveEditorState['runtime']['widgetsPlacement']>) => {
      state.runtime.widgetsPlacement = action.payload;
    },

    setIsPlaying: (state, action: PayloadAction<boolean>) => {
      state.runtime.isPlaying = action.payload;
    },

    setPlayButtonIsBlocked: (state, action: PayloadAction<boolean>) => {
      state.runtime.playButtonIsBlocked = action.payload;
    },
  },
});

export const {
  addWidget,
  setEditorState,
  resetEditorState,
  removeWidget,
  removeAllWidgets,
  updateWidget,
  updateWidgetData,
  updateWidgetProps,
  setWidgetsPlacement,
  setPlayButtonIsBlocked,
  setIsPlaying,
} = editorSlice.actions;

export default editorSlice.reducer;
