import { createApi } from '@reduxjs/toolkit/query/react';
import { strapiRestQuery } from '@lib/store/strapi-rest-query';

export const API_URL = process.env.GATSBY_API_URL + '/api';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: strapiRestQuery({
    baseUrl: API_URL + '/auth/local',
    prepareHeaders: (headers: Headers, { getState }) => {
      const { auth } = getState();
      const { jwt } = auth;

      if (jwt) {
        headers.set('Authorization', `Bearer ${jwt}`);
      }

      return headers;
    },
  }),

  endpoints: builder => ({
    login: builder.mutation({
      query: credentials => ({
        url: '/',
        method: 'POST',
        body: credentials,
      }),
    }),

    register: builder.mutation({
      query: credentials => ({
        url: '/register',
        method: 'POST',
        body: credentials,
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation } = authApi;
