import React, { useState, useRef, createContext, useCallback, useContext, useEffect } from 'react';

import { Grid } from '@mui/material';

import BottomBar from '@webapp/components/editors/common/bottom-bar';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { useEditor } from '@webapp/hooks/use-editor-hook';
import { useParams } from '@reach/router';
import { useGetEditorProjectQuery } from '@webapp/store/api/editor-project.api.graphql';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import { useAuth } from '@lib/auth';
import { useAppSettings } from '@lib/hooks/use-app-settings';
import { AppSettingsIds } from '@constants/app-settings-ids';

export const BOTTOM_BAR_HEIGHT = '110px';

export const allowedHandlers = {
  onPlay: 'onPlay', // handler will be called when user clicks play button
  onStop: 'onStop', // handler will be called when user clicks stop button
  onSave: 'onSave', // handler will be called when user saves the project
  onLoad: 'onLoad', // handler will be called when user loads the project
  onReset: 'onReset', // handler will be called when user resets the project
  onUndo: 'onUndo', // handler will be called when user clicks undo button
  onRedo: 'onRedo', // handler will be called when user clicks redo button
  onWidgetInstantionTry: 'onWidgetInstantionTry', // handler will be called when user tries to instantiate a widget
};

export const EditorContext = createContext({
  registerHandler: (handlerType, handlerCallback) => undefined,
  unregisterHandler: handlerType => undefined,
  registeredHandlers: {},
  editorType: null,
  isPlaying: false,
  setIsPlaying: isPlaying => undefined,
  currentEditorProject: null,
  currentEditorProjectIsLoading: false,
  currentEditorProjectError: null,
  currentEditorProjectRefetch: null,
});

export const useEditorContext = () => {
  return useContext(EditorContext);
};

export const useGetEditorType = () => {
  return useContext(EditorContext).editorType;
};

const withEditor = (WrappedComponent, editorType, containerStyles = {}) => {
  const EnhancedComponent = props => {
    const { isPlaying, setIsPlaying, setEditorState, resetEditorState, restoreStateFromSaved } = useEditor(editorType);
    const { client, store } = useRobo();

    const { user } = useAuth();
    const userRole = user?.role;

    const { getSettingValue } = useAppSettings();
    const disableMouseClick = getSettingValue(AppSettingsIds.WebAppDisableMouseClick) === 'true';

    const { addHistoryEntry } = useActionsHistory();

    const [registeredHandlers, setRegisteredHandlers] = useState({});

    const { projectId } = useParams();
    const currentEditorProject = useGetEditorProjectQuery({ id: projectId }, { skip: !projectId });

    const lastLoadedProjectIdRef = useRef(null);

    useEffect(() => {
      const project = currentEditorProject?.data?.editorProject?.data;

      if (project && project.id !== lastLoadedProjectIdRef.current) {
        if (project.attributes.editorState) {
          setEditorState(restoreStateFromSaved(project.attributes.editorState));
        }

        lastLoadedProjectIdRef.current = project.id;
      }
    }, [currentEditorProject]);

    useEffect(() => {
      return () => {
        resetEditorState();
      };
    }, []);

    // disable right mouse click for students in the editor
    useEffect(() => {
      if (userRole !== 'student' || !disableMouseClick) {
        return;
      }

      const contextMenuHandler = function (event) {
        event.preventDefault();
      };

      document.addEventListener('contextmenu', contextMenuHandler);
      return () => {
        document.removeEventListener('contextmenu', contextMenuHandler);
      };
    }, [userRole, disableMouseClick]);

    const systemStore = store.model.systems?.['SYSTEM_1'];
    const batteryStatus = systemStore?.batteryStatus ?? null;

    useEffect(() => {
      if (isPlaying && (batteryStatus === 'charging' || batteryStatus === 'charged')) {
        // user clicked stop button
        client?.setRunCommand(0);
        setIsPlaying(false);
        registeredHandlers.onStop && registeredHandlers.onStop();

        addHistoryEntry({
          action: `click:button:stop`,
          scope: editorType,
          data: {
            dataType: 'Empty',
          },
        });
      }
    }, [batteryStatus]);

    /**
     * Registers a handler with the given name and function.
     * @param {string} name - The name of the handler.
     * @param {Function} handler - The function to be registered as the handler.
     */
    const registerHandler = useCallback((name, handler) => {
      if (allowedHandlers[name]) {
        setRegisteredHandlers(prevHandlers => ({
          ...prevHandlers,
          [name]: handler,
        }));
      } else {
        console.error(`Handler name "${name}" is not allowed. Revisit EditorContext`);
      }
    }, []);

    /**
     * Removes a registered handler by name
     * @param {string} name - The name of the handler to remove
     */
    const unregisterHandler = useCallback(name => {
      setRegisteredHandlers(prevHandlers => {
        const newHandlers = { ...prevHandlers };
        delete newHandlers[name];
        return newHandlers;
      });
    }, []);

    // todo: refactor and remove this click handler. It should be a part of PlayStopButton component
    const handlePlayClick = () => {
      if (isPlaying) {
        // user clicked stop button
        client?.setRunCommand(0);
        setIsPlaying(false);

        registeredHandlers.onStop && registeredHandlers.onStop();

        addHistoryEntry({
          action: `click:button:stop`,
          scope: editorType,
          data: {
            dataType: 'Empty',
          },
        });
      } else {
        // user clicked play button
        client?.setRunCommand(1);
        setIsPlaying(true);

        registeredHandlers.onPlay && registeredHandlers.onPlay();

        addHistoryEntry({
          action: `click:button:start`,
          scope: editorType,
          data: {
            dataType: 'Empty',
          },
        });
      }
    };

    const containerRef = useRef(null);

    const injectedContentStyles =
      editorType === 'code'
        ? {
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            zIndex: 100,
          }
        : {};

    const injectedBottomBarStyles =
      editorType === 'code'
        ? {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: 90,
          }
        : {};

    return (
      <Grid
        container
        direction="column"
        wrap="nowrap"
        sx={{
          height: '100%',
          ...injectedContentStyles,
        }}
      >
        <EditorContext.Provider
          value={{
            containerRef,
            isPlaying: isPlaying,
            setIsPlaying,
            //legacy name
            showPlayButton: !isPlaying,
            registerHandler,
            unregisterHandler,
            registeredHandlers,
            editorType,
            currentEditorProject: currentEditorProject?.data?.editorProject?.data,
            currentEditorProjectIsLoading: currentEditorProject?.isLoading,
            currentEditorProjectError: currentEditorProject?.error,
            currentEditorProjectRefetch: currentEditorProject?.refetch,
          }}
        >
          <Grid
            item
            ref={containerRef}
            sx={{
              flexGrow: 1,
              maxWidth: '100% !important', // this is needed to crop content horizontally
              ...containerStyles,
            }}
          >
            <WrappedComponent {...props} />
          </Grid>

          <Grid
            item
            sx={{
              minHeight: BOTTOM_BAR_HEIGHT,
              ...injectedBottomBarStyles,
            }}
          >
            <BottomBar editorType={editorType} handlePlayClick={handlePlayClick} />
          </Grid>
        </EditorContext.Provider>
      </Grid>
    );
  };

  return EnhancedComponent;
};

export default withEditor;
