import { RoboClient } from './robo-client';
import { MODULE_MAPPINGS } from './module-mappings';
import * as AVAILABLE_MODULES from './modules';

import { ModulesStore, ModulesCollectionTypes, ModuleId } from './types';
import { ModuleType } from '@webapp/store/types';

export type AvailableModuleInstance = InstanceType<(typeof AVAILABLE_MODULES)[keyof typeof AVAILABLE_MODULES]>;

// Create a mapping type that ties the values of ModulesCollectionTypes to the specific classes in AVAILABLE_MODULES
type ModuleClassMapping = {
  [K in keyof typeof ModulesCollectionTypes]: InstanceType<(typeof AVAILABLE_MODULES)[K]>;
};

export class RoboModel {
  client: RoboClient;
  modules: {
    [K in keyof typeof ModulesCollectionTypes as (typeof ModulesCollectionTypes)[K]]: Record<
      ModuleId,
      ModuleClassMapping[K]
    >;
  };
  store?: ModulesStore;

  /**
   * Creates a new RoboModel instance.
   * @constructor
   * @param client - The client object.
   * @param modelStore - The store object.
   */
  constructor(client: RoboClient, modelStore?: ModulesStore) {
    this.client = client;

    this.bindStore(modelStore);

    // Dynamically create module proxies
    this.modules = Object.values(ModulesCollectionTypes).reduce(
      (acc, key) => {
        (acc as any)[key] = {};
        return acc;
      },
      {} as {
        [K in keyof typeof ModulesCollectionTypes as (typeof ModulesCollectionTypes)[K]]: Record<
          ModuleId,
          ModuleClassMapping[K]
        >;
      }
    );

    return new Proxy(this, {
      get(target, prop) {
        if (prop in target.modules) {
          return target.modules[prop as ModulesCollectionTypes];
        } else {
          return target[prop as keyof RoboModel];
        }
      },
    });
  }

  /**
   * Parses a configuration string and returns a new state object with the connected module indices for each module type.
   * @param configString - The configuration string to parse.
   * @returns A new state object with the connected module indices for each module type.
   */
  parseConfig(configString: Uint8Array): Record<string, number[]> {
    let binaryString = '';

    configString.forEach(byte => {
      binaryString += byte.toString(2).padStart(8, '0').split('').reverse().join(''); // Convert to binary and pad with zeros to 8 bits
    });

    // Initialize the new state
    const newState: Record<string, number[]> = {};

    // For each module type, get the connected module indices
    for (const [moduleType, moduleInfo] of Object.entries(MODULE_MAPPINGS)) {
      newState[moduleType] = moduleInfo.modulePositions.filter(index => binaryString[index] === '1');
    }

    return newState;
  }

  /**
   * Updates the state of the Robo instance based on a configuration string.
   * Instantiates or disables modules based on the new state.
   * @param configString - The configuration string to parse and update the state with.
   */
  updateConfig(configString: Uint8Array) {
    try {
      const newState = this.parseConfig(configString);

      // Instantiate or remove modules based on the new state
      for (const [moduleCollectionName, moduleSlots] of Object.entries(this.modules)) {
        const moduleMapping = MODULE_MAPPINGS[moduleCollectionName as ModulesCollectionTypes];

        if (!moduleMapping.enabled) {
          continue; // Skip this module type if it's not enabled in the mapping
        }

        const desiredClassName = AVAILABLE_MODULES[moduleMapping.className];

        const activeModulePositions = moduleMapping.modulePositions.reduce(
          (accumulator, modulePositionValue, modulePositionIndex) => {
            if (newState[moduleCollectionName].includes(modulePositionValue)) {
              accumulator.push(modulePositionIndex);
            }

            return accumulator;
          },
          [] as number[]
        );

        const activeModuleNames = activeModulePositions.map(modulePosition =>
          RoboModel.getModuleId(moduleCollectionName as ModulesCollectionTypes, modulePosition)
        );

        // If the module is not in the new state we remove the instance of the module if it's instantiated
        Object.entries(moduleSlots).forEach(([moduleName, module]) => {
          if (module && !activeModuleNames.includes(moduleName)) {
            module.disable();
            delete this.modules[moduleCollectionName as ModulesCollectionTypes][moduleName as ModuleId];
          }
        });

        // If the module is in the new state we create a new instance of the module if it's not already instantiated
        activeModuleNames.forEach(moduleName => {
          if (!this.modules[moduleCollectionName as ModulesCollectionTypes][moduleName as ModuleId]) {
            this.modules[moduleCollectionName as ModulesCollectionTypes][moduleName as ModuleId] = new desiredClassName(
              moduleName,
              this.client,
              this.store
            );

            this.modules[moduleCollectionName as ModulesCollectionTypes][moduleName as ModuleId].enable();
          }
        });
      }
    } catch (error) {
      console.error('ERROR', error);
      throw error;
    }
  }

  /**
   * Generates a module ID based on the module type and position.
   * @param moduleCollectionType - The type of the module.
   * @param modulePosition - The position of the module.
   * @returns A string representing the module ID.
   */
  static getModuleId(moduleCollectionType: ModulesCollectionTypes, modulePosition: number): ModuleId {
    const moduleMapping = MODULE_MAPPINGS[moduleCollectionType];
    return `${moduleMapping.idPrefix}${modulePosition + 1}` as ModuleId;
  }

  static parseModuleId(moduleId: ModuleId) {
    let modulePosition;

    const idPrefix = moduleId.replace(/_(\d+)$/, (_, position) => {
      modulePosition = position;
      return '_';
    });

    if (!modulePosition) {
      throw new Error('Not valid module id');
    }

    for (const moduleType in MODULE_MAPPINGS) {
      if (MODULE_MAPPINGS[moduleType as ModuleType].idPrefix === idPrefix) {
        return {
          moduleType: moduleType as ModuleType,
          modulePosition: parseInt(modulePosition),
        };
      }
    }

    throw Error(`Cannot parse this ID: ${moduleId}`);
  }

  /**
   * Binds the store to the model.
   * @param store - The store object.
   */
  bindStore(store?: ModulesStore) {
    this.store = store;
  }

  /**
   * Starts a batch sensors check.
   */
  startBatchSensorsCheck(modulesIds: ModuleId[] | null = null) {
    this.client.startBatchSensorsCheck(modulesIds);
  }

  /**
   * Stops a batch sensors check.
   */
  stopBatchSensorsCheck() {
    this.client.stopBatchSensorsCheck();
  }

  /**
   * Is running batch sensors check.
   */
  isRunningBatchSensorsCheck() {
    return this.client.isRunningBatchSensorsCheck();
  }
}
