import { useState } from 'react';

import { Box, Slider, Switch, Grid } from '@mui/material';

import { useSelector } from '@store/configureStore';

import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { elementsSelectors } from '@webapp/store/slices/code/elements.slice';

import { WidgetId } from '@webapp/store/types';

const DriveWidget = ({ id }: { id: WidgetId }) => {
  const { isPlaying } = useEditorContext();

  const { getWidgetById, updateWidgetData } = useCodeEditor();

  // get related widget
  const widget = getWidgetById(id);

  // if (!widget) return null;

  const { model: roboModel } = useRobo();
  const { data } = widget;

  console.log('data', data);

  const [distance, setDistance] = useState(data.distance);
  const [speed, setSpeed] = useState(data.speed);
  const [isReversed, setIsReversed] = useState(data.isReversed);
  const [bend, setBend] = useState(data.bend);

  const elementEntity = useSelector(state => elementsSelectors.selectElementById(state, data.elementId));

  const handleDistanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDistance = e.target.value;
    setDistance(newDistance);
  };

  const onDistanceChanged = () => {
    updateWidgetData(id, { distance });
  };

  const handleSpeedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSpeed = e.target.value;
    setSpeed(newSpeed);
  };

  const onSpeedChanged = () => {
    updateWidgetData(id, { speed });
  };

  const onIsReversedChanged = () => {
    setIsReversed(!isReversed);
    updateWidgetData(id, { isReversed: !isReversed });
  };

  const handleBendChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newBend = e.target.value;
    setBend(newBend);
  };

  const onBendChanged = () => {
    updateWidgetData(id, { bend });
  };

  return (
    <Box sx={{ width: '200px', height: '305px', display: 'flex', flexDirection: 'column' }}>
      {/* First row */}
      <Grid container spacing={0} sx={{ height: '64px' }}>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Dist.
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Spd.
        </Grid>
      </Grid>

      {/* Second row */}
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Slider
            value={distance}
            onChange={handleDistanceChange}
            onChangeCommitted={onDistanceChanged}
            orientation="vertical"
            sx={{ height: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', paddingBottom: 1 }}
        ></Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Slider
            value={speed}
            onChange={handleSpeedChange}
            onChangeCommitted={onSpeedChanged}
            orientation="vertical"
            sx={{ height: '100%' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ height: '64px' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Bend
          <Slider
            value={bend}
            onChange={handleBendChange}
            onChangeCommitted={onBendChanged}
            orientation="horizontal"
            sx={{ width: '100%' }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={0} sx={{ height: '64px' }}>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Sett.
        </Grid>

        <Grid item xs={6} />

        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Rev.
          <Switch checked={isReversed} onChange={onIsReversedChanged} />
        </Grid>
      </Grid>
    </Box>
  );
};

DriveWidget.initialData = {
  distance: 0,
  speed: 0,
  isReversed: false,
  bend: 0,
};

export default DriveWidget;
