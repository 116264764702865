import { useCallback } from 'react';

import { RootState, useDispatch, useSelector } from '@store/configureStore';
import { useToolbox } from '@webapp/hooks/use-toolbox-hook';

import { v4 as uuidv4 } from 'uuid';

import {
  EditorType,
  FlowNodeTypes,
  FlowEdgeTypes,
  CodeEditorState,
  CodeEditorGroup,
  CodeEditorAction,
  CodeEditorEdge,
  CodeEditorTrigger,
  CodeEditorTriggerPlacement,
  WidgetEntity,
  WidgetData,
  CodeEditorMode,
  CodeEditorTrashCanPosition,
} from '@webapp/store/types';

import {
  addEdge,
  removeEdge,
  groupSelector,
  addGroup,
  updateGroup,
  addAction,
  updateAction,
  removeAction,
  actionsByGroupIdSelector,
  updateLabel,
  labelsByGroupIdSelector,
  addTrigger,
  removeTrigger,
  updateTrigger,
  triggersByGroupIdSelector,
  triggersByEdgeIdSelector,
  edgesBetweenGroupsSelector,
  updateWidget,
  updateWidgetData,
  setMode,
  setTrashCanPosition,
} from '@webapp/store/slices/code/editor.slice';

import config from '../config';

export const NODE_TYPES = {
  group: FlowNodeTypes.Group,
  action: FlowNodeTypes.Action,
  dummyAction: FlowNodeTypes.DummyAction,
  trigger: FlowNodeTypes.Trigger,
  dummyTrigger: FlowNodeTypes.DummyTrigger,
  label: FlowNodeTypes.Label,
  dummyLabel: FlowNodeTypes.DummyLabel,
};

export const EDGE_TYPES = {
  custom: FlowEdgeTypes.Custom,
};

const useCodeEditor = () => {
  const editorState: CodeEditorState = useSelector((state: RootState) => state.webapp.code.editor);
  const { getElementById } = useToolbox(EditorType.Code);

  const dispatch = useDispatch();

  /** Add edge action */
  const _addEdge = (sourceGroupId: string, targetGroupId: string) => {
    const id = uuidv4();

    const edge = {
      id,
      sourceGroupId,
      targetGroupId,
      type: EDGE_TYPES.custom,
    } as CodeEditorEdge;

    dispatch(addEdge({ id, edge }));
  };

  /** Remove edge action */
  const _removeEdge = (edgeId: string) => {
    dispatch(removeEdge(edgeId));
  };

  /** Update group */
  const _updateGroup = (groupId: string, group: Partial<CodeEditorGroup>) => {
    dispatch(updateGroup({ id: groupId, group }));
  };

  /** Create trigger */
  const _createTrigger = (triggerPartial: Partial<CodeEditorTrigger>) => {
    const id = uuidv4();
    const trigger: CodeEditorTrigger = {
      id,
      ...triggerPartial,
    } as CodeEditorTrigger;

    dispatch(addTrigger({ id, trigger }));
  };

  /** Update trigger */
  const _updateTrigger = (triggerId: string, trigger: Partial<CodeEditorTrigger>) => {
    dispatch(updateTrigger({ id: triggerId, trigger }));
  };

  /** Remove trigger */
  const _removeTrigger = (triggerId: string) => {
    dispatch(removeTrigger(triggerId));
  };

  /** Change action group */
  const _changeActionGroup = (actionId: string, groupId: string) => {
    // get current group and action
    const currentGroupId = editorState.actions[actionId].groupId;

    // if action is already in the group, do nothing
    if (currentGroupId === groupId) return;

    // update action with new group
    dispatch(updateAction({ id: actionId, action: { groupId } }));
  };

  /** Change label group */
  const _changeLabelGroup = (labelId: string, groupId: string) => {
    // get current group and label
    const currentGroupId = editorState.labels[labelId].groupId;

    // if label is already in the group, do nothing
    if (currentGroupId === groupId) return;

    // update label with new group
    dispatch(updateLabel({ id: labelId, label: { groupId } }));
  };

  /** Update widget */
  const _updateWidget = <T>(widgetId: string, widget: Partial<WidgetEntity<T>>) => {
    dispatch(updateWidget({ id: widgetId, widget }));
  };

  const _updateWidgetData = <T>(widgetId: string, data: Partial<WidgetData<T>>) => {
    dispatch(updateWidgetData({ id: widgetId, data }));
  };

  /** Create new group and add action to it */
  const _createActionGroup = (actionId: string, initialGroupParams: Partial<CodeEditorGroup> = {}) => {
    const newGroupId = uuidv4();

    const newGroup = {
      id: newGroupId,
      position: { x: 0, y: 0 },
      ...initialGroupParams,
      actionIds: [actionId],
    } as CodeEditorGroup;

    // add new group with action
    dispatch(addGroup({ id: newGroupId, group: newGroup }));

    // update action with new group
    dispatch(updateAction({ id: actionId, action: { groupId: newGroupId } }));
  };

  /** Create new action */
  const _createAction = (actionPartial: Partial<CodeEditorAction>) => {
    const id = uuidv4();
    const action: CodeEditorAction = {
      id,
      ...actionPartial,
    } as CodeEditorAction;

    dispatch(addAction({ id, action }));
  };

  /** Create new action and group */
  const _createActionAndGroup = (actionPartial: Partial<CodeEditorAction>, groupPartial: Partial<CodeEditorGroup>) => {
    const groupId = uuidv4();
    const actionId = uuidv4();

    const group: CodeEditorGroup = {
      id: groupId,
      position: groupPartial.position || { x: 0, y: 0 },
      ...groupPartial,
    } as CodeEditorGroup;

    const action: CodeEditorAction = {
      id: actionId,
      ...actionPartial,
      groupId,
    } as CodeEditorAction;

    dispatch(addGroup({ id: groupId, group }));
    dispatch(addAction({ id: actionId, action }));
  };

  /** Remove action */
  const _removeAction = (actionId: string) => {
    dispatch(removeAction(actionId));
  };

  const _setMode = useCallback(
    (mode: CodeEditorMode) => {
      dispatch(setMode(mode));
    },
    [dispatch]
  );

  const mode = editorState.runtime.mode;

  const _setTrashCanPosition = useCallback(
    (position: CodeEditorTrashCanPosition) => {
      dispatch(setTrashCanPosition(position));
    },
    [dispatch]
  );

  const trashCanPosition = editorState.runtime.trashCanPosition;

  const getGroupById = useCallback(
    (groupId: string) => {
      return groupSelector(editorState, groupId);
    },
    [editorState]
  );

  /** Get actions by group id */
  const getActionsByGroupId = useCallback(
    (groupId: string) => {
      // Directly use the selector function with the current editorState and groupId
      return actionsByGroupIdSelector(editorState, groupId);
    },
    [editorState]
  );

  const getLabelsByGroupId = useCallback(
    (groupId: string) => {
      return labelsByGroupIdSelector(editorState, groupId);
    },
    [editorState]
  );

  const getTriggersByGroupId = useCallback(
    (groupId: string) => {
      return triggersByGroupIdSelector(editorState, groupId);
    },
    [editorState]
  );

  const getTriggersByEdgeId = useCallback(
    (edgeId: string) => {
      return triggersByEdgeIdSelector(editorState, edgeId);
    },
    [editorState]
  );

  const getTriggersByIds = useCallback(
    (triggerIds: string[]) => {
      return triggerIds.map(triggerId => editorState.triggers[triggerId]);
    },
    [editorState.triggers]
  );

  const getActionById = useCallback(
    (actionId: string) => {
      return editorState.actions[actionId];
    },
    [editorState.actions]
  );

  const getLabelById = useCallback(
    (labelId: string) => {
      return editorState.labels[labelId];
    },
    [editorState.labels]
  );

  const getEdgeById = useCallback(
    (edgeId: string) => {
      return editorState.edges[edgeId];
    },
    [editorState.edges]
  );

  const getTriggerById = useCallback(
    (triggerId: string) => {
      return editorState.triggers[triggerId];
    },
    [editorState.triggers]
  );

  const getPossibleTriggerElementPositionsByEdgeId = useCallback(
    (edgeId: string, triggerElementId: string) => {
      const edgeTriggers = getTriggersByEdgeId(edgeId);
      const edgeTriggerPlacements = edgeTriggers.map(trigger => trigger.placement);
      const allTriggersPositions = [
        CodeEditorTriggerPlacement.Base,
        CodeEditorTriggerPlacement.Or,
        CodeEditorTriggerPlacement.And,
      ];

      // 1. if there are 2 triggers on the edge, it means that trigger can not be added to the edge
      if (edgeTriggers.length === 2) {
        return [];
      }

      // 2. if the edge's triggers collection is empty, return Base position
      if (!edgeTriggers.length) {
        return [CodeEditorTriggerPlacement.Base];
      }

      // 3. if there is a trigger with the same elementId as the triggerElementId, we can't add it to the edge
      const triggerElementIdExists = edgeTriggers.some(trigger => trigger.elementId === triggerElementId);
      if (triggerElementIdExists) {
        return [];
      }

      // 4. return all the positions not occupied by the edge's triggers
      return allTriggersPositions.filter(position => !edgeTriggerPlacements.includes(position));
    },
    [getTriggersByEdgeId]
  );

  const getPossibleTriggerPositionsByEdgeId = useCallback(
    (edgeId: string, triggerId: string) => {
      const trigger = getTriggerById(triggerId);
      const edgeTriggers = getTriggersByEdgeId(edgeId);
      const edgeTriggerPlacements = edgeTriggers.map(trigger => trigger.placement);
      const allTriggersPositions = [
        CodeEditorTriggerPlacement.Base,
        CodeEditorTriggerPlacement.Or,
        CodeEditorTriggerPlacement.And,
      ];

      // 1. trigger is found in the edge
      if (edgeTriggers.includes(trigger)) {
        // 1.1 if the trigger is the only one on the edge, it means that it can't be moved anywhere
        if (edgeTriggers.length === 1) {
          return [];
        }

        // 1.2 if we are moving base trigger - do not allow to move
        if (trigger.placement === CodeEditorTriggerPlacement.Base) {
          return [];
        }

        // 1.3 calculate non-occupied positions by all triggers on the edge and return them
        return allTriggersPositions.filter(position => !edgeTriggerPlacements.includes(position));
      }

      // 2. trigger is not found in the edge. We are moving the trigger from another edge

      const triggersAmount = edgeTriggers.length;

      // 2.1 if the edge's triggers collection is empty, return Base position
      if (!triggersAmount) {
        return [CodeEditorTriggerPlacement.Base];
      }

      // 2.2 if there are 2 triggers on the edge, it means that trigger can not be added to the edge
      if (triggersAmount === 2) {
        return [];
      }

      // 2.3 if the edge's triggers collection is not empty, return all the positions not occupied by the edge's triggers
      const possiblePositions = allTriggersPositions.filter(position => !edgeTriggerPlacements.includes(position));

      // 2.3.1 if there is a trigger with the same elementId as the trigger we are moving, we can't add it to the edge
      const triggerElementId = trigger.elementId;
      const triggerElementIdExists = edgeTriggers.some(trigger => trigger.elementId === triggerElementId);
      if (triggerElementIdExists) {
        return [];
      }

      return possiblePositions;
    },
    [getTriggersByEdgeId, getTriggerById]
  );

  const getEdgesBetweenGroups = useCallback(
    (firstGroupId: string, secondGroupId: string) => {
      return edgesBetweenGroupsSelector(editorState, firstGroupId, secondGroupId);
    },
    [editorState]
  );

  const getWidgetById = useCallback(
    <T = Record<string, unknown>>(widgetId: string): WidgetEntity<T> | undefined => {
      const widget = editorState.widgets[widgetId];
      if (!widget) return undefined;
      return widget as WidgetEntity<T>;
    },
    [editorState.widgets]
  );

  const getModulesForWidgetId = useCallback(
    (widgetId: string) => {
      return editorState.widgets[widgetId].data?.moduleIds || [];
    },
    [editorState.widgets]
  );

  const canActionElementBeAddedToGroup = useCallback(
    (actionElementId: string, groupId: string) => {
      const groupActions = getActionsByGroupId(groupId);

      // If the group size has been exceeded, return false. We can't add more actions to the group
      if (groupActions.length >= config.maxActionsPerGroup) {
        return false;
      }

      const actionElement = getElementById(actionElementId);

      if (!actionElement) {
        console.warn('Action element not found', actionElementId);
        return false;
      }

      const allowedWidgetsPerGroupAmount = actionElement.widgetsPerGroup || 0;

      // widgetsLimit checks how many element related widgets can be created within the same group
      // 0 - unlimited
      // 1 - only one widget can be created
      // 2 - only two widgets can be created
      // etc...
      // retrieve all the actions that are related to the actionElement and check if the limit is exceeded
      const actionIdsForElementId = groupActions.filter(action => action.elementId === actionElementId);
      if (allowedWidgetsPerGroupAmount && actionIdsForElementId.length >= allowedWidgetsPerGroupAmount) {
        return false;
      }

      const actionElementModules = actionElement.requiredModules || [];
      const groupActionsWidgetIds = groupActions.map(action => action.widgetId);
      const groupActionsWidgetModules = groupActionsWidgetIds.map(widgetId => {
        if (!widgetId) {
          return [];
        }

        return getModulesForWidgetId(widgetId);
      });

      // if there is any module that is already in use by the group, return false
      const modulesExists = groupActionsWidgetModules.some(modules =>
        modules.some(module => actionElementModules.includes(module))
      );

      if (modulesExists) {
        // there are some actions that already use some of the modules of the actionElement
        return false;
      }

      return true;
    },
    [getActionsByGroupId, getModulesForWidgetId, getElementById]
  );

  const canActionBeAddedToGroup = useCallback(
    (actionId: string, groupId: string) => {
      const action: CodeEditorAction = getActionById(actionId);
      const elementId = action.elementId;

      return canActionElementBeAddedToGroup(elementId, groupId);
    },
    [getActionById, getActionsByGroupId]
  );

  const canTriggerElementBeAddedToGroupAndEdge = useCallback(
    (triggerElementId: string, groupId: string, edgeId: string) => {
      const groupTriggers = getTriggersByGroupId(groupId);

      const triggerElement = getElementById(triggerElementId);

      if (!triggerElement) {
        console.warn('Trigger element not found', triggerElementId);
        return false;
      }

      const allowedWidgetsPerGroupAmount = triggerElement.widgetsPerGroup || 0;

      // widgetsLimit checks how many element related widgets can be created within the same group
      // 0 - unlimited
      // 1 - only one widget can be created
      // 2 - only two widgets can be created
      // etc...
      // retrieve all the triggers that are related to the triggerElement and check if the limit is exceeded
      const triggerIdsForElementId = groupTriggers.filter(trigger => trigger.elementId === triggerElementId);
      if (allowedWidgetsPerGroupAmount && triggerIdsForElementId.length >= allowedWidgetsPerGroupAmount) {
        return false;
      }

      // check if we have the trigger related to the triggerElementId by the edgeId
      const triggerIdsForElementIdAndEdgeId = groupTriggers.filter(
        trigger => trigger.elementId === triggerElementId && trigger.edgeId === edgeId
      );

      if (triggerIdsForElementIdAndEdgeId.length) {
        return false;
      }

      const triggerElementModules = triggerElement.requiredModules || [];
      const groupTriggersWidgetIds = groupTriggers.map(trigger => trigger.widgetId);
      const groupTriggersWidgetModules = groupTriggersWidgetIds.map(widgetId => {
        if (!widgetId) {
          return [];
        }

        return getModulesForWidgetId(widgetId);
      });

      // if there is any module that is already in use by the group, return false
      const modulesExists = groupTriggersWidgetModules.some(modules =>
        modules.some(module => triggerElementModules.includes(module))
      );

      if (modulesExists) {
        // there are some triggers that already use some of the modules of the triggerElement
        return false;
      }

      return true;
    },
    [getTriggersByGroupId, getModulesForWidgetId, getElementById]
  );

  const canTriggerBeAddedToGroupAndEdge = useCallback(
    (triggerId: string, groupId: string, edgeId: string) => {
      const trigger = getTriggerById(triggerId);
      const elementId = trigger.elementId;

      return canTriggerElementBeAddedToGroupAndEdge(elementId, groupId, edgeId);
    },
    [getTriggerById, canTriggerElementBeAddedToGroupAndEdge]
  );

  return {
    updateGroup: _updateGroup,
    createTrigger: _createTrigger,
    updateTrigger: _updateTrigger,
    removeTrigger: _removeTrigger,
    removeEdge: _removeEdge,
    addEdge: _addEdge,
    createActionAndGroup: _createActionAndGroup,
    createAction: _createAction,
    removeAction: _removeAction,
    createActionGroup: _createActionGroup,
    changeActionGroup: _changeActionGroup,
    changeLabelGroup: _changeLabelGroup,
    updateWidget: _updateWidget,
    updateWidgetData: _updateWidgetData,
    setMode: _setMode,
    setTrashCanPosition: _setTrashCanPosition,

    getGroupById,
    getActionById,
    getActionsByGroupId,
    getLabelById,
    getLabelsByGroupId,
    getEdgeById,
    getEdgesBetweenGroups,
    getTriggerById,
    getTriggersByIds,
    getTriggersByGroupId,
    getTriggersByEdgeId,

    getPossibleTriggerPositionsByEdgeId,
    getPossibleTriggerElementPositionsByEdgeId,

    canActionBeAddedToGroup,
    canActionElementBeAddedToGroup,

    canTriggerBeAddedToGroupAndEdge,
    canTriggerElementBeAddedToGroupAndEdge,

    getWidgetById,
    getModulesForWidgetId,
    mode,
    trashCanPosition,
  };
};

export default useCodeEditor;
