import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, styled, SxProps } from '@mui/material';
import {
  createInitialMatrixState,
  isTouchEndCooldownPassed,
} from '@webapp/components/blocks/widgets/led-pixel-display-widget/utils';

type DragMode = 'on' | 'off';
type PixelMatrix = Array<boolean>;

type LedMatrixComponentProps = {
  size: number;
  disabled?: boolean;
  matrix: PixelMatrix;
  onMatrixChanged: (matrix: PixelMatrix) => void;
  onDragEnd?: (matrix: PixelMatrix) => void;
  sx?: SxProps;
  theming?: 'dark' | 'yellow';
};

const Container = styled(Box)<{ theming?: 'dark' | 'yellow'; size: number }>(({ theming, size }) => {
  const style =
    theming === 'dark'
      ? {
          padding: '8px',
          borderRadius: '10px',
          border: '1px solid #2C2B2B',
          background: '#2B2B40',
          boxShadow: '0px 1px 20px 0px rgba(0, 0, 0, 0.50) inset',
        }
      : {
          padding: '0px',
          borderRadius: '1px',
          border: '1px solid #27272733',
          background: '#FEC84B',
        };

  return {
    display: 'grid',
    gridTemplateColumns: `repeat(${size}, 1fr)`,
    rowGap: '0px',
    columnGap: '0px',
    flexGrow: 0,
    flexShrink: 0,
    aspectRatio: '1 / 1',
    height: '100%',
    ...style,
  };
});

const Pixel = styled(Box)<{ theming?: 'dark' | 'yellow'; selected: boolean }>(({ theming, selected }) => {
  return theming === 'dark'
    ? {
        padding: '1px',
        '@media (min-width: 1300px)': {
          padding: '2px',
        },
      }
    : {
        border: selected ? '1px solid #D4D4D4' : '1px solid #27272733',
      };
});

const PixelInner = styled(Box)<{ theming?: 'dark' | 'yellow'; selected: boolean }>(({ theming, selected }) => {
  return theming === 'dark'
    ? {
        maxWidth: '18px',
        maxHeight: '18px',
        width: '100%',
        height: '100%',
        borderRadius: '4px',
        userSelect: 'none',
        backgroundColor: selected ? 'white' : '#39394C',
      }
    : {
        width: '100%',
        height: '100%',
        userSelect: 'none',
        backgroundColor: selected ? 'white' : 'rgba(0,0,0,0)',
      };
});

export const LedPixelMatrixComponent = ({
  size,
  disabled,
  matrix,
  onMatrixChanged,
  onDragEnd,
  sx,
  theming = 'dark',
}: LedMatrixComponentProps) => {
  const [isDragging, setIsDragging] = useState<null | DragMode>(null);
  const touchEndAtRef = useRef<null | number>(null);

  const matrixRef = useRef<PixelMatrix>(createInitialMatrixState(size));
  const onDragEndRef = useRef<((matrix: PixelMatrix) => void) | undefined>(onDragEnd);

  useEffect(() => {
    onDragEndRef.current = onDragEnd;
  }, [onDragEnd]);

  useEffect(() => {
    matrixRef.current = matrix;
  }, [matrix]);

  const togglePixel = useCallback(
    (index: number): void => {
      onMatrixChanged(matrix.map((pixel, i) => (index === i ? !pixel : pixel)));
    },
    [matrix, onMatrixChanged]
  );

  const setPixel = useCallback(
    (index: number, value: boolean): void => {
      onMatrixChanged(matrix.map((pixel, i) => (index === i ? value : pixel)));
    },
    [matrix, onMatrixChanged]
  );

  const handleMouseDown = (index: number) => {
    const isTouchEnded = isTouchEndCooldownPassed(touchEndAtRef.current);
    if (!isTouchEnded || disabled) {
      return false;
    }
    setIsDragging(matrix[index] ? 'off' : 'on');
    togglePixel(index);

    const handleMouseUp = () => {
      setIsDragging(null);
      // use matrix from ref, because otherwise we will have outdated matrix from closure
      onDragEndRef.current?.(matrixRef.current);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseOver = (index: number) => {
    const isTouchEnded = isTouchEndCooldownPassed(touchEndAtRef.current);
    if (isDragging && isTouchEnded) {
      setPixel(index, isDragging === 'on');
    }
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (!isDragging) {
      return;
    }

    const touch = event.touches[0]; // Get the first touch
    const targetElement = document.elementFromPoint(touch.clientX, touch.clientY) as HTMLElement | null;

    // Make sure the element is one of our matrix boxes
    if (targetElement && targetElement.dataset.type === 'pixel' && targetElement.dataset.index !== undefined) {
      const index = parseInt(targetElement.dataset.index);
      if (index >= 0) {
        setPixel(index, isDragging === 'on');
      }
    }
  };

  const handleTouchStart = (_: React.TouchEvent<HTMLDivElement>, index: number) => {
    setIsDragging(matrix[index] ? 'off' : 'on');
    togglePixel(index);

    // Setup touch end to clean up after interaction
    const handleTouchEnd = () => {
      touchEndAtRef.current = Date.now();
      setIsDragging(null);
      onDragEndRef.current?.(matrixRef.current);
      // Clean up the event listener after use
      document.removeEventListener('touchend', handleTouchEnd);
    };
    document.addEventListener('touchend', handleTouchEnd);
  };

  const matrixContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      // this handler prevents mobile screens from scrolling when user drags above LED matrix
      e.preventDefault();
    };

    if (matrixContainerRef.current) {
      matrixContainerRef.current.addEventListener('touchmove', handleTouchMove, { passive: false });

      return () => {
        matrixContainerRef.current?.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, []);

  return (
    <Container theming={theming} size={size} ref={matrixContainerRef} onTouchMove={handleTouchMove} sx={sx}>
      {matrix.map((pixel, index) => (
        <Pixel
          theming={theming}
          key={`${index}`}
          selected={pixel}
          onMouseDown={() => {
            handleMouseDown(index);
          }}
          onMouseOver={() => {
            handleMouseOver(index);
          }}
          onTouchStart={e => {
            handleTouchStart(e, index);
          }}
          data-type="pixel" // Used for identifying the element in touch move
          data-index={index.toString()} // Used for identifying the pixel index in touch move
        >
          <PixelInner selected={pixel} theming={theming} />
        </Pixel>
      ))}
    </Container>
  );
};
