/**
 * A battery icon with a progressive fill based on the `progress` prop.
 * @param {object} props - The component props.
 * @param {number} [props.progress=0] - The progress of the battery, from 0 to 100.
 * @returns {JSX.Element} - The battery icon component.
 */
import { SvgIcon } from '@mui/material';
import { IconType } from '@webapp/components/icons/types';

type ProgressiveBatteryIconProps = {
  progress: number;
  color: string;
};

const Icon: IconType<ProgressiveBatteryIconProps> = props => {
  const progress = props.progress || 0;
  const color = props.color || progress > 10 ? '#5A418B' : '#fd1f02';

  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="1.05147"
          y="1.05147"
          width="19.6029"
          height="12.0147"
          rx="1.58088"
          stroke={color}
          strokeWidth="1.89706"
        />
        <rect x="20.3383" y="5.16187" width="3.16176" height="4.42647" rx="0.632353" fill={color} />

        {progress > 10 && (
          <path d="M5.16174 4.5293L5.16174 9.58812" stroke={color} strokeWidth="2.52941" strokeLinecap="round" />
        )}

        {progress > 40 && (
          <path d="M8.95593 4.5293V9.58812" stroke={color} strokeWidth="2.52941" strokeLinecap="round" />
        )}

        {progress > 70 && <path d="M12.75 4.5293V9.58812" stroke={color} strokeWidth="2.52941" strokeLinecap="round" />}

        {progress > 90 && (
          <path d="M16.5441 4.5293V9.58812" stroke={color} strokeWidth="2.52941" strokeLinecap="round" />
        )}
      </svg>
    </SvgIcon>
  );
};

export default Icon;
