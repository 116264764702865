import React from 'react';
import { ToggleButton as MuiToggleButton, ToggleButtonProps, styled } from '@mui/material';
import { Box } from '@mui/material';

interface CustomRoundToggleIconButtonProps {
  icon: React.ReactNode;
  text: React.ReactNode | string;
  mainColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}

type RoundToggleIconButtonProps = ToggleButtonProps & CustomRoundToggleIconButtonProps;

const StyledToggleButton = styled(MuiToggleButton, {
  shouldForwardProp: prop => !['mainColor', 'secondaryColor', 'tertiaryColor'].includes(prop as string),
})<{
  mainColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}>(({ theme, mainColor, secondaryColor, tertiaryColor, value }) => ({
  textTransform: 'none',
  borderRadius: '50%',
  border: 'none',
  width: '42px',
  height: '42px',
  padding: '0',
  backgroundColor: value ? mainColor : secondaryColor,
  color: value ? tertiaryColor : mainColor,
  '&:active': {
    backgroundColor: value ? mainColor : secondaryColor,
    color: value ? tertiaryColor : mainColor,
  },
  '&:hover': {
    backgroundColor: value ? mainColor : secondaryColor,
    opacity: 0.8,
  },
  '&[disabled]': {
    opacity: 0.5,
  },
}));

const RoundToggleIconButton: React.FunctionComponent<RoundToggleIconButtonProps> = ({
  icon,
  text,
  mainColor,
  secondaryColor,
  tertiaryColor,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
      }}
    >
      <StyledToggleButton
        mainColor={mainColor}
        secondaryColor={secondaryColor}
        tertiaryColor={tertiaryColor}
        {...props}
      >
        {/* {icon} */}
        {React.cloneElement(icon as React.ReactElement, {
          htmlColor: props.value ? tertiaryColor : mainColor,
        })}
      </StyledToggleButton>

      <Box sx={{ flex: '1 1 auto', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '4px' }}>
        {text}
      </Box>
    </Box>
  );
};

export default RoundToggleIconButton;
