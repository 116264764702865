import { useState } from 'react';

import { Box, Grid, ToggleButton } from '@mui/material';

import { useSelector } from '@store/configureStore';

import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { elementsSelectors } from '@webapp/store/slices/code/elements.slice';

import { WidgetId } from '@webapp/store/types';

enum EventType {
  PullDown = 'pullDown',
  PickUp = 'pickUp',
  Move = 'move',
}

const AccelerometerTriggerWidget = ({ id }: { id: WidgetId }) => {
  const { isPlaying } = useEditorContext();

  const { getWidgetById, updateWidgetData } = useCodeEditor();

  // get related widget
  const widget = getWidgetById(id);

  const { model: roboModel } = useRobo();
  const { data } = widget;

  const eventType = data?.eventType ?? AccelerometerTriggerWidget.initialData.eventType;

  const handleEventTypeChange = (e: React.MouseEvent<HTMLElement>, newEventType: EventType) => {
    updateWidgetData(id, { eventType: newEventType });
  };

  return (
    <Box sx={{ width: '360px', height: '185px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ToggleButton
            selected={eventType === EventType.PullDown}
            value={EventType.PullDown}
            onChange={e => handleEventTypeChange(e, EventType.PullDown)}
          >
            Pull Down
          </ToggleButton>

          <ToggleButton
            selected={eventType === EventType.PickUp}
            value={EventType.PickUp}
            onChange={e => handleEventTypeChange(e, EventType.PickUp)}
          >
            Pick Up
          </ToggleButton>

          <ToggleButton
            selected={eventType === EventType.Move}
            value={EventType.Move}
            onChange={e => handleEventTypeChange(e, EventType.Move)}
          >
            Move
          </ToggleButton>
        </Grid>
      </Grid>
    </Box>
  );
};

AccelerometerTriggerWidget.initialData = {
  eventType: EventType.PullDown,
};

export default AccelerometerTriggerWidget;
