import React, { useState, useContext, useMemo, useEffect } from 'react';
import { throttle } from 'lodash';

import { Typography, Slider, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { hslaToRgb } from '@lib/utils/color-utils';

import { colors as themeColors } from '@themes/config/theme-colors';
import { RgbLightIcon } from '@webapp/components/icons';
import StyledBox from '@webapp/components/blocks/component/styled-box';

import { EditorContext } from '@webapp/components/hoc/with-editor';

import { useRobo } from '@webapp/hooks/use-robo-hook';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';

const saturation = 100;
const lightness = 50;

const LedAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'status',
})(({ status }) => ({
  width: '76px',
  height: '76px',
  backgroundColor: status ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.3)',
}));

const StyledSlider = styled(Slider)(({ value }) => ({
  width: '62%',
  background: 'fff',
  borderRadius: 5,

  '& .MuiSlider-rail': {
    background: 'linear-gradient(to right, #f00, #ff0, #0f0, #0ff, #00f, #f0f, #f00)',
    boxShadow: '0 0 0 4px rgba(255, 255, 255, 1)',
    height: '8px',
    opacity: 1,
  },

  '& .MuiSlider-thumb': {
    width: '30px',
    height: '30px',
    boxShadow: '0 0 0 20px rgba(255, 255, 255, 1)',
    backgroundColor: `hsla(${value}, 100%, 50%, 4)`,
    opacity: 1,

    '&$focusVisible': {
      boxShadow: '0 0 0 20px rgba(255, 255, 255, 1)',
    },

    '&:focus, &:hover, &$active': {
      boxShadow: '0 0 0 20px rgba(255, 255, 255, 1)',
    },
  },

  '& .MuiSlider-thumb:hover': {
    boxShadow: '0 0 0 20px rgba(255, 255, 255, 1)',
  },

  '& .MuiSlider-thumb:focus,  & .MuiSlider-thumb$active': {
    boxShadow: '0 0 0 20px rgba(255, 255, 255, 1)',
  },
}));

const HueSlider = ({ value, onChange, disabled }) => {
  return (
    <StyledSlider
      value={value}
      onChange={onChange}
      min={0}
      max={360}
      step={1}
      aria-label="hue-slider"
      track={false}
      disabled={disabled}
    />
  );
};

const RGBLightWidget = ({ counter, data, disabled }) => {
  const [hue, setHue] = useState(0);
  const [isLedOn, setIsLedOn] = useState(true);
  const { addHistoryEntry } = useActionsHistory();

  const { isPlaying, editorType } = useContext(EditorContext);
  const { model: roboModel } = useRobo();

  const moduleId = data.moduleIds[0];
  const LED_RGB = roboModel?.ledRGBs[moduleId];

  const updateLedStateDebounced = useMemo(() => {
    return throttle((isLedOn, hueValue) => {
      const RGBValue = hslaToRgb(hueValue, saturation, lightness);

      setIsLedOn(isLedOn);
      LED_RGB?.setActive(isLedOn);
      if (isLedOn) {
        LED_RGB?.setColor(RGBValue.red, RGBValue.green, RGBValue.blue);
      }
      LED_RGB?.execute();

      addHistoryEntry({
        action: 'interact:widget:light',
        scope: editorType,
        data: {
          isLedOn,
          color: RGBValue,
          moduleId,
        },
      });
    }, 300);
  }, [LED_RGB, addHistoryEntry, editorType, moduleId]);

  const handleHueChange = (event, newValue) => {
    updateLedStateDebounced(isLedOn, newValue);
    setHue(newValue);
  };

  const toggleLedState = () => {
    updateLedStateDebounced(!isLedOn, hue);
  };

  useEffect(() => {
    if (isPlaying) {
      if (isLedOn) {
        const RGBValue = hslaToRgb(hue, saturation, lightness);
        LED_RGB?.setActive(true);
        LED_RGB?.setColor(RGBValue.red, RGBValue.green, RGBValue.blue);
        LED_RGB?.execute();
      }
    } else {
      LED_RGB?.setActive(false);
      LED_RGB?.execute();
    }
  }, [isPlaying]);

  return (
    <StyledBox
      color={disabled ? WidgetColors.disabledBackground : '#FFB71C'}
      direction="row"
      paddingLeft="10px"
      paddingRight="48px"
      justify="space-between"
    >
      <Typography
        variant="x-headline5-semibold"
        color={themeColors.white['500']}
        sx={{ position: 'absolute', top: '5px', left: '91px' }}
      >
        {counter}
      </Typography>

      <LedAvatar status={isLedOn} onClick={isPlaying ? toggleLedState : null}>
        <RgbLightIcon sx={{ width: '58px', height: '58px' }} />
      </LedAvatar>

      <HueSlider value={hue} onChange={handleHueChange} disabled={!isPlaying || disabled} />
    </StyledBox>
  );
};

RGBLightWidget.initialProps = {
  width: 4,
  height: 1,
};

RGBLightWidget.initialData = {};

export default RGBLightWidget;
