import { createEntityAdapter, createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@store/configureStore';
import { ElementsState, ElementEntity, EditorType } from '@webapp/store/types';

import { isSubset } from '@lib/utils/array-utils';

import { selectConnectedModuleIds, selectConnectedModuleTypes } from '@webapp/store/slices/model/model.selectors';

/**
 * Creates a sections slice with its actions, its reducer, its adapter, and its selectors.
 * @param elementsInitialState The initial state of the elements slice.
 * @param sliceName The name of the slice.
 * @param editor The editor type.
 * @param specificReducers The specific reducers of the slice.
 * @returns The elements slice, its actions, its reducer, its adapter, and its selectors.
 */
export const createElementsSlice = (
  elementsInitialState: ElementsState,
  sliceName: string,
  editor: EditorType,
  specificReducers: Record<string, (state: ElementsState, action: PayloadAction) => void>
) => {
  const elementsState = (state: RootState) => state.webapp[editor].toolbox.elements as ElementsState;

  const elementsAdapter = createEntityAdapter<ElementEntity>();

  const elementsSlice = createSlice({
    name: sliceName,
    initialState: elementsInitialState,
    reducers: {
      addElement: elementsAdapter.addOne,
      updateElement: elementsAdapter.updateOne,
      removeElement: elementsAdapter.removeOne,
      hideAllElements: (state: ElementsState) => {
        Object.values(state.entities).forEach(entity => {
          entity.hidden = true;
        });
      },
      showAllElements: (state: ElementsState) => {
        Object.values(state.entities).forEach(entity => {
          entity.hidden = false;
        });
      },
      hideElement: (state: ElementsState, action: PayloadAction<string>) => {
        state.entities[action.payload].hidden = true;
      },
      showElement: (state: ElementsState, action: PayloadAction<string>) => {
        state.entities[action.payload].hidden = false;
      },
      activateElement: (state: ElementsState, action: PayloadAction<string>) => {
        state.entities[action.payload].active = true;
      },
      deactivateElement: (state: ElementsState, action: PayloadAction<string>) => {
        state.entities[action.payload].active = false;
      },

      ...specificReducers,
    },
  });

  const adapterSelectors: ReturnType<typeof elementsAdapter.getSelectors> = elementsAdapter.getSelectors(elementsState);
  /**
   * Selects all elements belonging to a specific section.
   * @param state - The root state of the application.
   * @param sectionId - The ID of the section to select elements from.
   * @returns An array of elements belonging to the specified section.
   */
  const selectElementsBySection = createSelector(
    [adapterSelectors.selectAll, (_state: RootState, sectionId: string | null) => sectionId],
    (elements, sectionId) => {
      if (sectionId === null) {
        return [];
      }
      return elements.filter(element => element.sectionId === sectionId);
    }
  );

  /**
   * Selects elements that belong to connected modules.
   * @returns An array of elements that belong to connected modules.
   */
  const selectElementsForConnectedModules = createSelector(
    [adapterSelectors.selectAll, selectConnectedModuleIds, selectConnectedModuleTypes],
    (elements, connectedModuleIds, connectedModuleTypes) => {
      return elements.filter(element => {
        let elementShouldBeIncluded = true;

        if (element.requiredModules?.length) {
          // check required modules fulfillment
          elementShouldBeIncluded &&= element.requiredModules.every((moduleId: string) =>
            connectedModuleIds.includes(moduleId)
          );
        }

        if (element.requiredModuleTypes?.length) {
          // check required module types fulfillment
          elementShouldBeIncluded &&= isSubset(element.requiredModuleTypes, connectedModuleTypes);
        }

        return elementShouldBeIncluded;
      });
    }
  );

  /**
   * Selects elements that belong to connected modules within a specific section.
   * @param state - The root state of the application.
   * @param sectionId - The ID of the section to select elements from.
   * @returns An array of elements belonging to connected modules within the specified section.
   */
  const selectElementsForConnectedModulesBySection = createSelector(
    [selectElementsBySection, selectElementsForConnectedModules],
    (elementsBySection, elementsForConnectedModules) => {
      // Now we have two arrays, one filtered by section and one by connected modules.
      // We want the intersection of these two arrays.
      return elementsBySection.filter(element =>
        elementsForConnectedModules.some(connectedElement => connectedElement.id === element.id)
      );
    }
  );

  const selectElementById = createSelector([adapterSelectors.selectById], element => element);
  const selectAllElements = createSelector([adapterSelectors.selectAll], elements => elements);
  const selectActiveElement = createSelector([selectAllElements], elements => elements.find(element => element.active));

  return {
    elementsSlice: elementsSlice,
    actions: elementsSlice.actions,
    reducer: elementsSlice.reducer,
    elementsAdapter: elementsAdapter,
    selectors: {
      ...adapterSelectors,
      selectElementsBySection,
      selectElementsForConnectedModules,
      selectElementsForConnectedModulesBySection,
      selectElementById,
      selectAllElements,
      selectActiveElement,
    },
  };
};
