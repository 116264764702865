import { request, gql as gqlClient, ClientError } from 'graphql-request';

import { store } from '@store/configureStore';
import { clearCredentials } from '@store/slices/auth.slice';

export const strapiGraphqlQuery = ({
  baseUrl,
  prepareHeaders,
}: {
  baseUrl: string;
  prepareHeaders: CallableFunction;
}) => {
  return async (args, opts) => {
    const { getState } = opts;
    const headers = new Headers();

    if (typeof prepareHeaders === 'function') {
      prepareHeaders(headers, { getState });
    }

    try {
      const result = await request({
        url: baseUrl,
        requestHeaders: headers,
        ...args,
      });

      // return processResponse(result)
      return { data: result };
    } catch (error) {
      return processError(error);
    }
  };
};

const processError = (error: object) => {
  if (error.response?.status === 401) {
    store.dispatch(clearCredentials());
  }

  if (error instanceof ClientError) {
    const allErrors = error.response.errors || [];
    const firstError = allErrors[0];
    const message = firstError?.message || error.response?.error?.message || 'Client Error';

    return {
      error: {
        status: error.response?.status || 400,
        data: { message: message },
      },
    };
  }

  return {
    error: {
      status: error.status || 500,
      data: { message: error.message || 'Unexpected error' },
    },
  };
};

export const gql = gqlClient;
