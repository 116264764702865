import { useState } from 'react';

import { Box, Grid, ToggleButton } from '@mui/material';

import { useSelector } from '@store/configureStore';

import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { elementsSelectors } from '@webapp/store/slices/code/elements.slice';

import { WidgetId } from '@webapp/store/types';

enum EventType {
  Near = 'near',
  Far = 'far',
}

const ObstacleTriggerWidget = ({ id }: { id: WidgetId }) => {
  const { isPlaying } = useEditorContext();

  const { getWidgetById, updateWidgetData } = useCodeEditor();

  // get related widget
  const widget = getWidgetById(id);

  const { model: roboModel } = useRobo();
  const { data } = widget;

  const [eventType, setEventType] = useState<EventType>(data.eventType);

  const elementEntity = useSelector(state => elementsSelectors.selectElementById(state, data.elementId));

  const handleEventTypeChange = (e: React.MouseEvent<HTMLElement>, newEventType: EventType) => {
    setEventType(newEventType);
    updateWidgetData(id, { eventType: newEventType });
  };

  return (
    <Box sx={{ width: '360px', height: '185px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ToggleButton
            selected={eventType === EventType.Near}
            value={EventType.Near}
            onChange={e => handleEventTypeChange(e, EventType.Near)}
          >
            Near
          </ToggleButton>

          <ToggleButton
            selected={eventType === EventType.Far}
            value={EventType.Far}
            onChange={e => handleEventTypeChange(e, EventType.Far)}
          >
            Far
          </ToggleButton>
        </Grid>
      </Grid>
    </Box>
  );
};

ObstacleTriggerWidget.initialData = {
  eventType: EventType.Near,
};

export default ObstacleTriggerWidget;
