import { debounce } from 'lodash';

import { BaseModule, IRoboStore, onActionDoneType } from './base-module';
import { RoboClient } from '@lib/robo/robo-client';
import { ModulesCollectionTypes } from '@lib/robo/types';

export enum ROTATION_DIRECTIONS {
  cw = 0,
  ccw = 1,
}

export class Motor extends BaseModule<typeof Motor> {
  constructor(id: string, client: RoboClient, store: IRoboStore) {
    super(id, client, ModulesCollectionTypes.System, store);
  }

  /**
   * Converts the speed value to hex.
   *
   * @param {number} newValue - The speed value to convert.
   * @param {ROTATION_DIRECTIONS} rotationDirection - The rotation direction.
   * @returns {string} The hex value.
   */
  convertToHex(newValue: number, rotationDirection: ROTATION_DIRECTIONS) {
    let minHex, maxHex;

    if (rotationDirection === ROTATION_DIRECTIONS.cw) {
      minHex = newValue < 0 ? 0x01 : 0xfe;
      maxHex = newValue < 0 ? 0x64 : 0x9c;
    } else {
      minHex = newValue > 0 ? 0x01 : 0xfe;
      maxHex = newValue > 0 ? 0x64 : 0x9c;
    }

    if (newValue != 0) {
      const range = maxHex - minHex + 1;
      const positiveNumber = Math.abs(newValue);
      const hexValue = minHex + Math.floor(((range - 1) * (positiveNumber - 1)) / 99);

      return `0x${hexValue.toString(16).toUpperCase()}`;
    } else if (newValue === 0) {
      return '0x00';
    }
  }

  /**
   * Sets the angle of the motor.
   * @param {number} angle - The angle to set.
   * @param {string} [rotationDirection=ROTATION_DIRECTIONS.cw] - The direction of rotation (default: clockwise).
   */
  angle(angle: number, rotationDirection: ROTATION_DIRECTIONS = ROTATION_DIRECTIONS.cw) {
    this.client.motorAngleAction(1, this.index, angle, !!rotationDirection);
  }

  /**
   * Sets the speed of the motor.
   * @param {number} speed - The speed value.
   * @param {number} [rotationDirection=ROTATION_DIRECTIONS.cw] - The rotation direction. Defaults to clockwise.
   */
  setSpeed(speed: number, rotationDirection: ROTATION_DIRECTIONS = ROTATION_DIRECTIONS.cw) {
    // @kobylin TODO: check if this is correct. The convertToHex returns a string, but the setMotor expects a number
    const speedHexValue = this.convertToHex(speed, rotationDirection);
    this.client.setMotor(this.index, speedHexValue);
  }

  /**
   * Sets the speed of the motor with debounce functionality.
   * @param {number} speed - The speed value to set.
   * @param {number} rotationDirection - The rotation direction. Default is 'cw'.
   */
  setSpeedDebounced = debounce(this.setSpeed, 50);

  /**
   * Sets the motor action.
   * @param speed - The speed value to set.
   * @param distance - The distance to set.
   * @param onActionDone - The callback to call when the action is done.
   * @returns The action id.
   */
  setMotorAction(speed: number, distance: number, onActionDone: onActionDoneType) {
    const actionId = this.generateActionOrTriggerId();

    this.subscribeToResponse(actionId, onActionDone);

    this.client.setMotorAction(actionId, this.index, speed, 0x5a /** wheel diameter */, distance);

    return { actionId };
  }

  /**
   * Sets the angle of the motor.
   * @param angle - The angle to set.
   * @param rotationDirection - The rotation direction. Default is 'cw'.
   * @param onActionDone - The callback to call when the action is done.
   * @returns The action id.
   */
  setAngleAction(angle: number, rotationDirection: ROTATION_DIRECTIONS, onActionDone: onActionDoneType) {
    const actionId = this.generateActionOrTriggerId();

    this.subscribeToResponse(actionId, onActionDone);

    this.client.motorAngleAction(actionId, this.index, angle, !!rotationDirection);

    return { actionId };
  }
}
