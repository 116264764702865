import { useState } from 'react';

import { Box, Grid, MenuItem, Select, Typography, SelectChangeEvent } from '@mui/material';

import { useSelector } from '@store/configureStore';

import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { elementsSelectors } from '@webapp/store/slices/code/elements.slice';

import { WidgetId } from '@webapp/store/types';

const ClockTriggerWidget = ({ id }: { id: WidgetId }) => {
  const { isPlaying } = useEditorContext();

  const { getWidgetById, updateWidgetData } = useCodeEditor();

  // get related widget
  const widget = getWidgetById(id);

  const { model: roboModel } = useRobo();
  const { data } = widget;

  const [minutes, setMinutes] = useState<number>(data.minutes);
  const [seconds, setSeconds] = useState<number>(data.seconds);

  const elementEntity = useSelector(state => elementsSelectors.selectElementById(state, data.elementId));

  const handleMinutesChange = (e: SelectChangeEvent<number>) => {
    const newMinutes = e.target.value;
    setMinutes(+newMinutes);
    updateWidgetData(id, { minutes: +newMinutes });
  };

  const handleSecondsChange = (e: SelectChangeEvent<number>) => {
    const newSeconds = e.target.value;
    setSeconds(+newSeconds);
    updateWidgetData(id, { seconds: +newSeconds });
  };

  return (
    <Box sx={{ width: '320px', height: '265px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Select value={minutes} onChange={handleMinutesChange}>
            <MenuItem value="0">0</MenuItem>
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography>:</Typography>
        </Grid>

        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Select value={seconds} onChange={handleSecondsChange}>
            {Array.from({ length: 60 }, (_, i) => (
              <MenuItem value={i} key={i}>
                {i < 10 ? `0${i}` : i}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Box>
  );
};

ClockTriggerWidget.initialData = {
  minutes: 0,
  seconds: 0,
};

export default ClockTriggerWidget;
