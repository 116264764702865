import { Node, Edge, NodeProps } from 'reactflow';

import { WidgetsState, WidgetId, WidgetType } from './index';

export type CodeGroupId = string;
export type CodeActionId = string;
export type CodeEdgeId = string;
export type CodeTriggerId = string;
export type CodeLabelId = string;
export type ElementId = string;
export type DummyId = string;

/**
 * Represents the label types for the code editor.
 */
export enum CodeEditorLabelType {
  Start = 'start',
  Other = 'other',
}

/**
 * Represents the trigger placement for the code editor.
 */
export enum CodeEditorTriggerPlacement {
  Base = 'base',
  And = 'and',
  Or = 'or',
}

export type CodeEditorTrashCanPosition = 'left' | 'right';
/**
 * Represents the state of the code editor in the store.
 */
export type CodeEditorState = {
  widgets: WidgetsState;

  groups: Record<CodeGroupId, CodeEditorGroup>;
  actions: Record<CodeActionId, CodeEditorAction>;
  edges: Record<CodeEdgeId, CodeEditorEdge>;
  triggers: Record<CodeTriggerId, CodeEditorTrigger>;
  labels: Record<CodeLabelId, CodeEditorLabel>;

  runtime: {
    isPlaying: boolean;
    playButtonIsBlocked: boolean;
    zoomIsEnabled: boolean;
    connectGroupsIsEnabled: boolean;
    recordSensorsIsEnabled: boolean;
    trashCanPosition: CodeEditorTrashCanPosition;
    mode: CodeEditorMode;
  };
};

export type SetCodeEditorStatePayload = Omit<CodeEditorState, 'runtime'>;

/**
 * Represents a group in the code editor state.
 */
export type CodeEditorGroup = {
  id: CodeGroupId;

  position: {
    x: number;
    y: number;
  };

  actionIds: CodeActionId[];
  edgeIds: CodeEdgeId[];
  triggerIds: CodeTriggerId[];
  labelIds: CodeLabelId[];
};

/**
 * Represents an action in the code editor state.
 */
export type CodeEditorAction = {
  id: CodeActionId;

  groupId: CodeGroupId;
  elementId: ElementId;

  widgetId?: WidgetId;
  widgetType: WidgetType;
};

/**
 * Represents an edge in the code editor.
 */
export type CodeEditorEdge = {
  id: CodeEdgeId;

  sourceGroupId: CodeGroupId;
  targetGroupId: CodeGroupId;
};

/**
 * Represents a trigger in the code editor.
 */
export type CodeEditorTrigger = {
  id: CodeTriggerId;
  placement: CodeEditorTriggerPlacement;

  groupId: CodeGroupId;
  edgeId: CodeEdgeId;
  elementId: ElementId;

  widgetId?: WidgetId;
  widgetType: WidgetType;
};

export type CodeEditorLabel = {
  id: CodeLabelId;
  type: CodeEditorLabelType;

  groupId: CodeGroupId;
};

/**
 * Payload for adding a group to the code editor.
 */
export type CodeEditorAddGroupPayload = {
  id: CodeGroupId;
  group: CodeEditorGroup;
};

/**
 * Payload for removing a group from the code editor.
 */
export type CodeEditorRemoveGroupPayload = {
  id: CodeGroupId;
};

/**
 * Payload for updating a group in the code editor.
 */
export type CodeEditorUpdateGroupPayload = {
  id: CodeGroupId;
  group: Partial<CodeEditorGroup>;
};

/**
 * Payload for adding an action to the code editor.
 */
export type CodeEditorAddActionPayload = {
  id: CodeActionId;
  action: CodeEditorAction;
};

/**
 * Payload for removing an action from the code editor.
 */
export type CodeEditorRemoveActionPayload = {
  id: CodeActionId;
};

/**
 * Payload for updating an action in the code editor.
 */
export type CodeEditorUpdateActionPayload = {
  id: CodeActionId;
  action: Partial<CodeEditorAction>;
};

/**
 * Payload for adding an edge to the code editor.
 */
export type CodeEditorAddEdgePayload = {
  id: CodeEdgeId;
  edge: CodeEditorEdge;
};

/**
 * Payload for removing an edge from the code editor.
 */
export type CodeEditorRemoveEdgePayload = {
  id: CodeEdgeId;
};

/**
 * Payload for updating an edge in the code editor.
 */
export type CodeEditorUpdateEdgePayload = {
  id: CodeEdgeId;
  edge: Partial<CodeEditorEdge>;
};

/**
 * Payload for adding a trigger to the code editor.
 */
export type CodeEditorAddTriggerPayload = {
  id: CodeTriggerId;
  trigger: CodeEditorTrigger;
};

/**
 * Payload for removing a trigger from the code editor.
 */
export type CodeEditorRemoveTriggerPayload = {
  id: CodeTriggerId;
};

/**
 * Payload for updating a trigger in the code editor.
 */
export type CodeEditorUpdateTriggerPayload = {
  id: CodeTriggerId;
  trigger: Partial<CodeEditorTrigger>;
};

/**
 * Payload for adding a label to the code editor.
 */
export type CodeEditorAddLabelPayload = {
  id: CodeLabelId;
  label: CodeEditorLabel;
};

/**
 * Payload for removing a label from the code editor.
 */
export type CodeEditorRemoveLabelPayload = {
  id: CodeLabelId;
};

/**
 * Payload for updating a label in the code editor.
 */
export type CodeEditorUpdateLabelPayload = {
  id: CodeLabelId;
  label: Partial<CodeEditorLabel>;
};

/**
 * Enum representing the types of flow nodes.
 */
export enum FlowNodeTypes {
  Group = 'group-node',
  Action = 'action-node',
  DummyAction = 'dummy-action-node',
  Trigger = 'trigger-node',
  DummyTrigger = 'dummy-trigger-node',
  Label = 'label-node',
  DummyLabel = 'dummy-label-node',
}

/**
 * Represents the types of edges in the react flow editor.
 */
export enum FlowEdgeTypes {
  Custom = 'custom-edge',
}

/**
 * Node type for the flow editor.
 */
export type FlowNode = Node & {
  id: string;
  type: FlowNodeTypes;
  parentNode?: string;

  data?: {
    instance?: CodeEditorGroup | CodeEditorAction | CodeEditorTrigger | CodeEditorLabel;
    reset?: boolean; // used during primarily for Action nodes. It is used to reset the node to its original position on node repositioning
  } & Record<string, unknown>;

  dummies?: DummyFlowNode[]; // should be used only for groups
  position: { x: number; y: number };
};

/**
 * Dummy node type for the flow editor.
 */
export type DummyFlowNode = FlowNode & {
  dummyType: DummyFlowNodeTypes;
  data: {
    dummyType: DummyFlowNodeTypes;
  };
  replace?: string; // internal field
};

/**
 * Props type for dummy flow nodes.
 */
export type DummyFlowNodeProps = NodeProps & {
  data: {
    dummyType: DummyFlowNodeTypes;
  } & Record<string, unknown>;
};

/**
 * Enum representing the types of dummy flow nodes.
 */
export enum DummyFlowNodeTypes {
  SourceDummyAction = 'source-dummy-action-node',
  TargetDummyAction = 'target-dummy-action-node',
  SourceDummyTrigger = 'source-dummy-trigger-node',
  TargetDummyTrigger = 'target-dummy-trigger-node',
  SourceDummyLabel = 'source-dummy-label-node',
  TargetDummyLabel = 'target-dummy-label-node',
}

/**
 * Edge type for the flow editor.
 */
export type FlowEdge = Edge & {
  id: string;
  type: FlowEdgeTypes;
  source: string;
  target: string;
  data?: {
    instance?: CodeEditorEdge;
    origin?: EdgeCoordinates; // used for directed nodes, such as triggers or dummy triggers
    angle?: number; // used for directed nodes, such as triggers or dummy triggers
    isBiDirectional?: boolean;
  } & Record<string, unknown>;
};

/**
 * Types for edges cache
 */
export type EdgeCoordinates = {
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
};

export type EdgeCacheEntry = {
  edgeCoordinates: EdgeCoordinates;
  angle: number;
  isBiDirectional: boolean;
  layouts: Record<string, Map<CodeEditorTriggerPlacement, { x: number; y: number }>>;
};

export type EdgeCache = Record<CodeEdgeId, EdgeCacheEntry>;

export enum CodeEditorMode {
  BUILD = 'build',
  CONNECT = 'connect',
  RUN = 'run',
  LOCK = 'lock',
}
