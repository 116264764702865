import { BaseModule } from './base-module';
import { convertBytesArrayToInt } from '@lib/utils/hex';

const ACCELEROMETER_COEFFICIENT = 1;

export class Accelerometer extends BaseModule {
  constructor(id, client, store) {
    super(id, client, 'accelerometers', store);

    this.client.registerHandler(
      'onAccelerometerState',
      ({ data }) => {
        this.updateDataState({
          sensorData: {
            acceleration: Accelerometer.parsePayload(data),
          },
        });
      },
      id
    );
  }

  /**
   * Convert raw number to gravity.
   *
   * @param {number} number - The raw number to be converted.
   * @returns {number} - The gravity value. 1 - is 1G, 2 is 2G, etc
   */
  static convertRawNumberToGravity(number) {
    let gravity;
    if (number > 32767) {
      gravity = (number - 65535 + 1) / ACCELEROMETER_COEFFICIENT;
    } else {
      gravity = number / ACCELEROMETER_COEFFICIENT;
    }

    return gravity / 100;
  }

  static parsePayload(data) {
    const x = Accelerometer.convertRawNumberToGravity(convertBytesArrayToInt([data[1], data[0]]));
    const y = Accelerometer.convertRawNumberToGravity(convertBytesArrayToInt([data[3], data[2]]));
    const z = Accelerometer.convertRawNumberToGravity(convertBytesArrayToInt([data[5], data[4]]));

    return {
      x,
      y,
      z,
    };
  }
}
