import { LiveEditorState, CodeEditorState } from '@webapp/store/types';

export type LiveEditorStateForSave = Omit<LiveEditorState, 'runtime'>;

export type CodeEditorStateForSave = Omit<CodeEditorState, 'runtime'>;

/**
 * Prepares the live editor state for saving.
 * @param state - The live editor state to prepare for saving.
 * @returns The prepared live editor state for saving.
 */
export const prepareLiveEditorStateForSave = (state: LiveEditorState): LiveEditorStateForSave => {
  return {
    widgets: state.widgets,
  };
};

/**
 * Restores the live editor state from saved state.
 * @param savedState - The saved state to restore the live editor state from.
 * @returns The restored live editor state.
 */
export const restoreLiveEditorFromSaved = (savedState: {
  widgets: LiveEditorState['widgets'];
}): {
  widgets: LiveEditorState['widgets'];
} => {
  return {
    widgets: savedState.widgets,
  };
};

/**
 * Prepares the code editor state for saving.
 * @param state - The code editor state to prepare for saving.
 * @returns The prepared code editor state for saving.
 */
export const prepareCodeEditorStateForSave = (state: CodeEditorState): CodeEditorStateForSave => {
  const { runtime: _, ...preparedState } = state;

  const stateForSave = {
    ...preparedState,
    widgets: Object.fromEntries(
      Object.entries(preparedState.widgets).map(([widgetId, widget]) => {
        if (widget.data?.runtime) {
          const { runtime: _, ...dataWithoutRuntime } = widget.data;
          return [widgetId, { ...widget, data: dataWithoutRuntime }];
        }
        return [widgetId, widget];
      })
    ),
  };

  // console.log('stateForSave', stateForSave);

  return stateForSave;
};

/**
 * Restores the code editor state from saved state.
 * @param savedState - The saved state to restore the code editor state from.
 * @returns The restored code editor state.
 */
export const restoreCodeEditorStateFromSaved = (state: CodeEditorState): CodeEditorState => {
  return state;
};
