export type Rect = {
  x: number;
  y: number;
};

export type Dimension = {
  width: number;
  height: number;
  position: Rect;
};

// Get degree of line between two points
export const getAngle = (x1: number, y1: number, x2: number, y2: number) => {
  const angleRadians = Math.atan2(y2 - y1, x2 - x1);
  const angleDegrees = angleRadians * (180 / Math.PI);
  return angleDegrees;
};

/**
 * Clamp the given value between the given minimum and maximum values.
 *
 * @param {number} value - The value to clamp.
 * @param {number} min - The minimum value to clamp to.
 * @param {number} max - The maximum value to clamp to.
 * @returns {number} - The clamped value.
 */
export const clamp = (value: number, min: number, max: number): number => Math.min(Math.max(value, min), max);

export const mapToRange = (
  value: number,
  [inputMin, inputMax]: [number, number],
  [outputMin, outputMax]: [number, number]
) => {
  return ((outputMax - outputMin) / (inputMax - inputMin)) * (value - inputMin) + outputMin;
};

export const getLineWithOffset = (
  x1: number,
  y1: number,
  r1: number,
  x2: number,
  y2: number,
  r2: number,
  offset = 0,
  extraLength = 0
) => {
  r1 = r1 - extraLength;
  r2 = r2 - extraLength;

  // Distance between centers
  const d = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

  // Unit direction vector from O1 to O2
  const ux = (x1 - x2) / d;
  const uy = (y1 - y2) / d;

  // Perpendicular direction to the original line
  const vx = -uy;
  const vy = ux;

  // Displacing the centers along the direction perpendicular to the original line
  const displaced_x1 = x1 + offset * vx;
  const displaced_y1 = y1 + offset * vy;
  const displaced_x2 = x2 + offset * vx;
  const displaced_y2 = y2 + offset * vy;

  // Calculating the shortest line between the displaced centers
  const d_displaced = Math.sqrt((displaced_x2 - displaced_x1) ** 2 + (displaced_y2 - displaced_y1) ** 2);
  const ux_displaced = (displaced_x2 - displaced_x1) / d_displaced;
  const uy_displaced = (displaced_y2 - displaced_y1) / d_displaced;

  const l1x = displaced_x1 + r1 * ux_displaced;
  const l1y = displaced_y1 + r1 * uy_displaced;
  const l2x = displaced_x2 - r2 * ux_displaced;
  const l2y = displaced_y2 - r2 * uy_displaced;

  return { sourceX: l1x, sourceY: l1y, targetX: l2x, targetY: l2y };
};

// calculate coordinates difference between two points
export const calculateCoordinatesDifference = (
  startCoordinates: { x: number; y: number },
  endCoordinates: { x: number; y: number }
) => {
  return {
    x: endCoordinates.x - startCoordinates.x,
    y: endCoordinates.y - startCoordinates.y,
  };
};

// calculate vector end coordinates, when start coordinates, length and angle are given
export const calculateVectorEndCoordinates = (
  coordinates: { x: number; y: number },
  vectorLength: number,
  angleDegrees: number
) => {
  // Convert angle from degrees to radians
  const angleRadians = angleDegrees * (Math.PI / 180); // Convert angle from degrees to radians

  // Calculate end coordinates
  const xEnd = coordinates.x + vectorLength * Math.cos(angleRadians);
  const yEnd = coordinates.y + vectorLength * Math.sin(angleRadians);

  return { x: xEnd, y: yEnd };
};

// Calculate free area for a block to be placed between other blocks
export const findFreeArea = (
  currentBlockDimension: Dimension,
  otherBlockDimensions: Dimension[],
  safeZoneSize: number,
  maxSearchDistance = 2000 // Default maximum search distance
): Rect | null => {
  // Helper function to check if two blocks intersect
  const intersects = (block1: Dimension, block2: Dimension): boolean => {
    return !(
      block2.position.x >= block1.position.x + block1.width + safeZoneSize ||
      block2.position.x + block2.width + safeZoneSize <= block1.position.x ||
      block2.position.y >= block1.position.y + block1.height + safeZoneSize ||
      block2.position.y + block2.height + safeZoneSize <= block1.position.y
    );
  };

  let distance = safeZoneSize; // Start searching at one safeZoneSize distance
  const increment = safeZoneSize; // Increment distance by safeZoneSize each round

  while (distance <= maxSearchDistance) {
    for (let dx = -distance; dx <= distance; dx += increment) {
      for (let dy = -distance; dy <= distance; dy += increment) {
        // Consider only the perimeter of the current search area
        if (Math.abs(dx) !== distance && Math.abs(dy) !== distance) continue;

        const newPosition = { x: currentBlockDimension.position.x + dx, y: currentBlockDimension.position.y + dy };
        const tempDimension = { ...currentBlockDimension, position: newPosition };

        // Check if this new position intersects with any existing blocks
        const isIntersecting = otherBlockDimensions.some(otherBlock => intersects(tempDimension, otherBlock));

        // If it does not intersect, we found a free spot
        if (!isIntersecting) {
          return { x: Math.round(newPosition.x), y: Math.round(newPosition.y) };
        }
      }
    }
    distance += increment; // Expand the search area for the next iteration
  }

  return null; // Return null if no free spot is found within the max search distance
};
