import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import { styled } from '@mui/system';
import { FatCloseIcon, RotateIcon, SunIcon } from '@webapp/components/icons';
import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { LedDisplay } from '@lib/robo/modules';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { colors as themeColors } from '@themes/config/theme-colors';
import {
  convertToTwoDimensionalArray,
  createInitialMatrixState,
  rotateOneDimensionalMatrix,
} from '@webapp/components/blocks/widgets/led-pixel-display-widget/utils';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';
import { ModuleId } from '@lib/robo/types';
import { useEditor } from '@webapp/hooks/use-editor-hook';
import { EditorType, WidgetData } from '@webapp/store/types';
import { LedPixelMatrixComponent } from '@webapp/components/blocks/component/led-pixel-matrix-component';

const StyledTypography = styled(Typography)(() => ({
  position: 'absolute',
  top: '2px',
  left: '5px',
  variant: 'x-lead-semibold',
  color: themeColors.white['500'],
}));

type MatrixWidgetProps = {
  counter: number;
  disabled: boolean;
  data: WidgetData<unknown>;
};

const CircularWidgetButton = styled('button')<{ on?: boolean }>(({ on = true }) => ({
  width: '100%',
  maxWidth: '76px',
  aspectRatio: '1 / 1',
  borderRadius: '50%',
  border: 'none',
  background: on ? 'rgba(255, 255, 255, 0.30)' : 'rgba(0, 0, 0, 0.30)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  ':disabled': {
    opacity: 0.5,
  },
}));

const LedPixelCircularWidgetButton = styled(CircularWidgetButton)(() => ({
  svg: {
    path: {
      fill: 'white',
    },
  },
}));

/**
 * Get the orientation of the LED display widget.
 * @param {ModuleId[]} moduleIds - An array of module IDs
 * @returns {number} - The converted angle to orientation value of the LED display widget
 */
export const useLedDisplayWidgetOrientation = (moduleIds: ModuleId[]): number => {
  const { getWidgetsByModuleIds } = useEditor(EditorType.Live);
  const ledDisplayWidget = getWidgetsByModuleIds(moduleIds).find(widget => widget.type === 'led-pixel-display-widget');
  const defaultRotationAngle = 0;

  return LedDisplay.convertAngleToOrientation((ledDisplayWidget?.data?.rotation as number) ?? defaultRotationAngle);
};

const LedPixelDisplayWidget = ({ data, counter, disabled }: MatrixWidgetProps) => {
  const size = LedDisplay.DisplaySize;
  const [rotation, setRotation] = useState(0);
  const [isOn, setIsOn] = useState(true);
  const [matrix, setMatrix] = useState<boolean[]>(() => {
    return createInitialMatrixState(size);
  });
  const { addHistoryEntry } = useActionsHistory();
  const { editorType } = useEditorContext();
  const { isPlaying } = useEditorContext();
  const { model: roboModel } = useRobo();

  if (!editorType) {
    throw new Error('Empty editor type');
  }

  const { updateWidgetData } = useEditor(editorType);
  const widgetId = data.id;

  const matrixRef = useRef<boolean[]>(createInitialMatrixState(size));
  useEffect(() => {
    matrixRef.current = matrix;
  }, [matrix]);

  const moduleId = data.moduleIds[0] as ModuleId;
  const MODULE = roboModel?.modules.ledDisplays[moduleId];

  const toggleDisplay = () => {
    if (!MODULE || disabled) {
      return;
    }

    if (isOn) {
      MODULE.stop();
      setIsOn(false);
    } else {
      sendMatrixToLedDisplay(matrix, rotation);
      setIsOn(true);
    }

    addHistoryEntry({
      action: 'interact:widget:led-matrix.on',
      scope: editorType,
    });
  };

  const sendMatrixToLedDisplay = (matrix: boolean[], rotation: number) => {
    if (!MODULE) {
      return;
    }

    // matrix is rotated virtually and always passed with 0 degrees rotation to the module
    // because it works faster this way
    const rotatedMatrix = rotateOneDimensionalMatrix(matrix, size, rotation);
    const twoDimMatrix = convertToTwoDimensionalArray(rotatedMatrix, size);
    MODULE.setImage(twoDimMatrix, LedDisplay.Rotation.Degrees_0);

    addHistoryEntry({
      action: 'interact:widget:led-matrix',
      scope: editorType,
    });
  };

  const clearScreen = (): void => {
    if (disabled) {
      return;
    }
    const initialMatrix = createInitialMatrixState(size);
    setMatrix(initialMatrix);
    sendMatrixToLedDisplay(initialMatrix, rotation);
  };

  const rotateLedDisplay = () => {
    if (!MODULE || disabled) {
      return;
    }

    const nextRotation = (rotation + 90) % 360;
    setRotation(nextRotation);
    sendMatrixToLedDisplay(matrixRef.current, nextRotation);
    updateWidgetData(widgetId, {
      rotation: nextRotation,
    });
    addHistoryEntry({
      action: 'interact:widget:led-matrix.rotate',
      scope: editorType,
    });
  };

  // set matrix to led display on mount and when isPlaying changes
  // or when widget disabled state changes
  useEffect(() => {
    if (!MODULE) {
      return;
    }

    if (isPlaying) {
      if (isOn) {
        sendMatrixToLedDisplay(matrix, rotation);
      }
    } else {
      MODULE.setImage(convertToTwoDimensionalArray(createInitialMatrixState(size), size));
    }
  }, [isPlaying, disabled, MODULE]);

  return (
    <Box
      sx={{
        display: 'inline-flex',
        background: disabled ? WidgetColors.disabledBackground : '#F0B339',
        padding: '10px',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
      }}
    >
      <StyledTypography variant="x-lead-semibold">{counter}</StyledTypography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          marginRight: '22px',
          marginTop: '10px',
          marginBottom: '10px',
          marginLeft: '10px',
          flexGrow: 1,
          alignItems: 'center',
        }}
      >
        <LedPixelCircularWidgetButton on={isOn} onClick={toggleDisplay}>
          <SunIcon />
        </LedPixelCircularWidgetButton>
        <LedPixelCircularWidgetButton onClick={rotateLedDisplay}>
          <RotateIcon />
        </LedPixelCircularWidgetButton>
        <LedPixelCircularWidgetButton onClick={clearScreen}>
          <FatCloseIcon />
        </LedPixelCircularWidgetButton>
      </Box>
      <LedPixelMatrixComponent
        theming="dark"
        size={size}
        matrix={matrix}
        disabled={!isPlaying}
        onMatrixChanged={matrix => {
          setMatrix(matrix);
        }}
        onDragEnd={matrix => {
          if (isOn) {
            sendMatrixToLedDisplay(matrix, rotation);
          }
        }}
      />
    </Box>
  );
};

LedPixelDisplayWidget.initialProps = {
  width: 4,
  height: 3,
};

export default LedPixelDisplayWidget;
