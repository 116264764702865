import React, { useState, useContext, useMemo } from 'react';

import { Typography } from '@mui/material';

import { ROTATION_DIRECTIONS } from '@lib/robo/modules/motor';
import { colors as themeColors } from '@themes/config/theme-colors';
import MotorDirectionToggleButton from './motor-components/motor-direction-toggle-button';
import StyledBox from '@webapp/components/blocks/component/styled-box';
import StyledSlider from '@webapp/components/blocks/component/styled-slider';

import { EditorContext, useGetEditorType } from '@webapp/components/hoc/with-editor';

import { useRobo } from '@webapp/hooks/use-robo-hook';
import { useActionsHistory } from '@webapp/hooks/use-actions-history-hook';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';

const MIN_VALUE = -100;
const MAX_VALUE = 100;

const MotorWidget = ({ counter, data, disabled }) => {
  const [value, setValue] = useState(0);
  const [direction, setDirection] = useState('left');

  const { addHistoryEntry } = useActionsHistory();
  const editorType = useGetEditorType();
  const { isPlaying } = useContext(EditorContext);
  const { model: roboModel } = useRobo();

  const moduleId = data.moduleIds[0];
  const MOTOR = roboModel?.motors[moduleId];

  const handleChangeDirection = (_, value) => {
    if (value !== null) {
      setDirection(value);
    }
  };

  const rotateMotor = speed => {
    const rotationDirection = direction === 'left' ? ROTATION_DIRECTIONS.cw : ROTATION_DIRECTIONS.ccw;
    MOTOR?.setSpeedDebounced(speed, rotationDirection);

    addHistoryEntry(
      {
        action: 'interact:widget:motor',
        scope: editorType,
        data: {
          moduleId,
          speed,
          direction,
        },
      },
      {
        debounce: true,
      }
    );
  };

  const handleSliderChange = (event, newValue) => {
    rotateMotor(newValue);
    setValue(newValue);
  };

  const trackStyle = useMemo(() => {
    if (value > 0) {
      return {
        bottom: '50% !important',
        top: 'unset !important',
        height: `${(value / MAX_VALUE) * 50}% !important`,
        transition: 'none !important',
      };
    } else if (value < 0) {
      return {
        bottom: 'unset !important',
        top: '50% !important',
        height: `${(value / MIN_VALUE) * 50}% !important`,
        transition: 'none !important',
      };
    } else {
      return {
        display: 'none',
      };
    }
  }, [value]);

  return (
    <StyledBox color={disabled ? WidgetColors.disabledBackground : '#156BFB'}>
      <Typography variant="x-lead-semibold" color={themeColors.white['500']} sx={{ paddingBottom: '30px' }}>
        {counter}
      </Typography>

      <StyledSlider
        orientation="vertical"
        min={MIN_VALUE}
        max={MAX_VALUE}
        value={value}
        onChange={handleSliderChange}
        onChangeCommitted={() => {
          setValue(0);
          MOTOR?.setSpeedDebounced.cancel();
          MOTOR?.setSpeed(0);
        }}
        thumb="80px"
        track={'normal'}
        border="2px solid #5A418B"
        disabled={!isPlaying || disabled}
        sx={{
          '& .MuiSlider-track': trackStyle,
        }}
      />

      <MotorDirectionToggleButton onChange={handleChangeDirection} value={direction} />
    </StyledBox>
  );
};

MotorWidget.initialProps = {
  width: 1,
  height: 4,
};

MotorWidget.initialData = {};

MotorWidget.propTypes = {};

MotorWidget.defaultProps = {};

export default MotorWidget;
