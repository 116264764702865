// Import individual widget components
import MotorWidget from './motor/motor-widget';
import MotorAngleWidget from './motor-angle/motor-angle-widget';
import JoystickWidget from './joystick/joystick-widget';
import RgbLightWidget from './rgb-light/rgb-light-widget';
import LaughingFaceWidget from './laughing-face/laughing-face-widget';
import SmilingFaceWidget from './smiling-face/smiling-face-widget';
import LookingFaceWidget from './looking-face/looking-face-widget';
import MotionSensorWidget from './motion-sensor/motion-sensor-widget';
import LightSensorWidget from './light-sensor/light-sensor-widget';
import DistanceSensorWidget from './distance-sensor/distance-sensor-widget';
import LineFollowerWidget from './line-follower/line-follower-widget';
import LiveSoundWidget from './sound/live-sound-widget';
import ButtonWidget from './button-sensor/button-sensor-widget';
import LedPixelDisplayWidget from './led-pixel-display-widget/led-pixel-display-widget';
import AccelerometerWidget from './accelerometer-sensor/accelerometer-sensor-widget';

import CodeSoundWidget from './sound/code-sound-widget';
import CodeMotorWidget from './code/motor-action/code-motor-widget';
import CodeAngleWidget from './code/angle-action/code-angle-widget';
import CodeShineWidget from './code/shine-action/code-shine-widget';
import CodeFlashWidget from './code/flash-action/code-flash-widget';
import CodeDriveWidget from './code/drive-action/code-drive-widget';
import CodeWaitWidget from './code/wait-action/code-wait-widget';
import CodeMessageWidget from './code/message-action/code-message-widget';
import CodeRepeatTriggerWidget from './code/repeat-trigger/code-repeat-trigger-widget';
import CodeTimerTriggerWidget from './code/timer-trigger/code-timer-trigger-widget';
import CodeButtonTriggerWidget from './code/button-trigger/code-button-trigger-widget';
import CodeAccelerometerTriggerWidget from './code/accelerometer-trigger/code-accelerometer-trigger-widget';
import CodeObstacleTriggerWidget from './code/obstacle-trigger/code-obstacle-trigger-widget';
import CodeMotionTriggerWidget from './code/motion-trigger/code-motion-trigger-widget';
import CodeLightTriggerWidget from './code/light-trigger/code-light-trigger-widget';
import CodeDistanceTriggerWidget from './code/distance-trigger/code-distance-trigger-widget';
import CodeClockTriggerWidget from './code/clock-trigger/code-clock-trigger-widget';
import CodeDrawWidget from '@webapp/components/blocks/widgets/code/draw-action/code-draw-widget';
import CodeAnimateWidget from '@webapp/components/blocks/widgets/code/animate-action/code-animate-widget';
import CodeTextWidget from '@webapp/components/blocks/widgets/code/text-action/code-text-widget';

// Export all widgets as a single object
export const availableWidgets = {
  MotorWidget,
  MotorAngleWidget,
  JoystickWidget,
  RgbLightWidget,
  LaughingFaceWidget,
  SmilingFaceWidget,
  LookingFaceWidget,
  MotionSensorWidget,
  LightSensorWidget,
  DistanceSensorWidget,
  LineFollowerWidget,
  LiveSoundWidget,
  ButtonWidget,
  LedPixelDisplayWidget,
  AccelerometerWidget,

  // todo Extract to separate file
  CodeSoundWidget,
  CodeMotorWidget,
  CodeAngleWidget,
  CodeShineWidget,
  CodeFlashWidget,
  CodeDriveWidget,
  CodeWaitWidget,
  CodeMessageWidget,
  CodeRepeatTriggerWidget,
  CodeTimerTriggerWidget,
  CodeButtonTriggerWidget,
  CodeAccelerometerTriggerWidget,
  CodeObstacleTriggerWidget,
  CodeMotionTriggerWidget,
  CodeLightTriggerWidget,
  CodeDistanceTriggerWidget,
  CodeClockTriggerWidget,
  CodeDrawWidget,
  CodeAnimateWidget,
  CodeTextWidget,
};

// Export individual widgets
export {
  MotorWidget,
  MotorAngleWidget,
  JoystickWidget,
  RgbLightWidget,
  LaughingFaceWidget,
  SmilingFaceWidget,
  LookingFaceWidget,
  MotionSensorWidget,
  LightSensorWidget,
  DistanceSensorWidget,
  LineFollowerWidget,
  LiveSoundWidget,
  ButtonWidget,
  LedPixelDisplayWidget,
  AccelerometerWidget,
  CodeSoundWidget,
  CodeMotorWidget,
  CodeAngleWidget,
  CodeShineWidget,
  CodeFlashWidget,
  CodeDriveWidget,
  CodeWaitWidget,
  CodeMessageWidget,
  CodeRepeatTriggerWidget,
  CodeTimerTriggerWidget,
  CodeButtonTriggerWidget,
  CodeAccelerometerTriggerWidget,
  CodeObstacleTriggerWidget,
  CodeMotionTriggerWidget,
  CodeLightTriggerWidget,
  CodeDistanceTriggerWidget,
  CodeClockTriggerWidget,
  CodeAnimateWidget,
  CodeTextWidget,
};

// Default export
export default availableWidgets;
