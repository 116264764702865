import { useState, useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Ultrasonic, DistanceCondition } from '@lib/robo/modules/ultrasonic';

import { useRobo } from '@webapp/hooks/use-robo-hook';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import MultitrackSlider from '@webapp/components/ui/sliders/multitrack-slider';
import ComparisonToggleButton from '@webapp/components/ui/buttons/comparison-toggle-button';

import { DistanceLowIcon, DistanceHighIcon } from '@webapp/components/icons';

import { AbortablePromise } from '@lib/utils/abortable-promise';
import { ExecutableTriggerWidgetComponent, WidgetExecutionType } from '@webapp/store/types';

import { ModuleId } from '@lib/robo/types';

const widgetConfig = {
  distanceValue: {
    min: 0,
    max: 150,
  },
};

const DistanceTriggerWidget: ExecutableTriggerWidgetComponent<DistanceTriggerWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData } = useCodeEditor();

  const widget = getWidgetById<DistanceTriggerWidgetData>(id);

  const widgetData = widget?.data;

  const { model: roboModel } = useRobo();
  const moduleId = widgetData?.moduleIds[0] as ModuleId;

  const [distance, setDistance] = useState<number>(widgetData?.distance ?? DistanceTriggerWidget.initialData.distance);

  const [condition, setCondition] = useState<DistanceCondition>(
    widgetData?.condition ?? DistanceTriggerWidget.initialData.condition
  );

  const DISTANCE_SENSOR = roboModel?.modules.ultrasonics[moduleId];

  let currentDistance = -1;

  if (DISTANCE_SENSOR) {
    const moduleDataState = DISTANCE_SENSOR?.getDataState();
    currentDistance = moduleDataState?.sensorData?.distance ?? 0;

    if (currentDistance > widgetConfig.distanceValue.max) {
      currentDistance = widgetConfig.distanceValue.max;
    }
  }

  /**
   * Starts a batch sensors check for the ultrasonic sensor when the widget is mounted and stops it when the widget is unmounted.
   */
  useEffect(() => {
    if (!roboModel) return;

    const isCheckRunning = roboModel.isRunningBatchSensorsCheck();

    if (DISTANCE_SENSOR) {
      if (!isCheckRunning) {
        roboModel.startBatchSensorsCheck([DISTANCE_SENSOR.id as ModuleId]);
      }
    } else {
      if (isCheckRunning) {
        roboModel.stopBatchSensorsCheck();
      }
    }

    return () => {
      if (roboModel.isRunningBatchSensorsCheck()) {
        roboModel.stopBatchSensorsCheck();
      }
    };
  }, [DISTANCE_SENSOR, roboModel]);

  const handleDistanceChange = (newDistance: number) => {
    setDistance(newDistance);
  };

  const onDistanceChanged = (newDistance: number) => {
    updateWidgetData(id, { distance: newDistance });
  };

  const handleConditionChange = (newCondition: DistanceCondition) => {
    setCondition(newCondition);
    updateWidgetData(id, { condition: newCondition });
  };

  return (
    <Box sx={{ width: '360px', height: '240px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={0} sx={{ height: '100%', flexDirection: 'column' }}>
        <Grid item sx={{ display: 'flex', height: '90px', justifyContent: 'center', alignItems: 'flex-end' }}>
          <ComparisonToggleButton
            mainColor="#DF1642"
            selected={condition === DistanceCondition.LessThan}
            value={DistanceCondition.LessThan}
            onChange={() =>
              handleConditionChange(
                condition === DistanceCondition.LessThan ? DistanceCondition.GreaterThan : DistanceCondition.LessThan
              )
            }
          />

          <Typography variant="x-headline5-regular" color="#5A418B" sx={{ ml: 1, width: '70px', textAlign: 'left' }}>
            {distance} cm
          </Typography>
        </Grid>

        <Grid container item sx={{ flexGrow: 1, mt: -6 }}>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <DistanceLowIcon sx={{ fontSize: '26px' }} />
          </Grid>

          <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MultitrackSlider
              value={distance}
              secondValue={currentDistance}
              onChange={(_, newValue) => handleDistanceChange(newValue as number)}
              onChangeCommitted={(_, newValue) => onDistanceChanged(newValue as number)}
              sx={{ width: '100%' }}
              min={widgetConfig.distanceValue.min}
              max={widgetConfig.distanceValue.max}
              appearance={condition === DistanceCondition.LessThan ? 'default' : 'reversed'}
            />
          </Grid>

          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <DistanceHighIcon sx={{ fontSize: '60px' }} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Executes the trigger for the light sensor widget.
 */
DistanceTriggerWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById }) => {
  return AbortablePromise(
    signal,
    async (resolve, reject) => {
      const widget = getWidgetById(widgetId);

      if (!widget) {
        throw new Error('Widget not found');
      }

      const { data } = widget;
      const moduleId = data?.moduleIds[0];
      const DISTANCE_SENSOR = roboModel.modules.ultrasonics[moduleId as ModuleId];

      if (!DISTANCE_SENSOR) {
        reject(new Error('Ultrasonic sensor module not found'));
        return;
      }

      // Create trigger for button module
      DISTANCE_SENSOR.createTrigger(data.condition, data.distance, ({ isError }) => {
        if (isError) {
          reject(new Error('Error with distance trigger'));
        } else {
          resolve({
            widgetId: widget.id,
            resolved: true,
            type: WidgetExecutionType.Trigger,
          });
        }
      });
    },
    `DistanceTriggerWidget.execute for widgetId: ${widgetId}`
  );
};

export type DistanceTriggerWidgetData = {
  condition: DistanceCondition;
  distance: number;
};

DistanceTriggerWidget.initialData = {
  condition: DistanceCondition.LessThan,
  distance: 30,
};

export default DistanceTriggerWidget;
