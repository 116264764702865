import { useCallback, useMemo } from 'react';

import { RootState, useDispatch, useSelector } from '@store/configureStore';

import {
  EditorType,
  WidgetProps,
  WidgetData,
  AddWidgetPayload,
  RemoveWidgetPayload,
  LiveEditorState,
  SetLiveEditorStatePayload,
  SetCodeEditorStatePayload,
  CodeEditorState,
} from '@webapp/store/types';

import {
  setEditorState as LIVE_setEditorState,
  resetEditorState as LIVE_resetEditorState,
  addWidget as LIVE_addWidget,
  removeAllWidgets as LIVE_removeAllWidgets,
  removeWidget as LIVE_removeWidget,
  setWidgetsPlacement as LIVE_setWidgetsPlacement,
  updateWidgetData as LIVE_updateWidgetData,
  updateWidgetProps as LIVE_updateWidgetProps,
  setPlayButtonIsBlocked as LIVE_setPlayButtonIsBlocked,
  setIsPlaying as LIVE_setIsPlaying,
} from '@webapp/store/slices/live/editor.slice';

import {
  selectUsedModulesIds as COMMON_selectUsedModulesIds,
  selectWidgetsModulesIdsMap,
} from '@webapp/store/slices/shared/selectors';

import {
  setEditorState as CODE_setEditorState,
  resetEditorState as CODE_resetEditorState,
  addWidget as CODE_addWidget,
  removeAllWidgets as CODE_removeAllWidgets,
  removeWidget as CODE_removeWidget,
  updateWidgetData as CODE_updateWidgetData,
  updateWidgetProps as CODE_updateWidgetProps,
  setPlayButtonIsBlocked as CODE_setPlayButtonIsBlocked,
  setIsPlaying as CODE_setIsPlaying,
} from '@webapp/store/slices/code/editor.slice';

import {
  prepareLiveEditorStateForSave,
  restoreLiveEditorFromSaved,
  prepareCodeEditorStateForSave,
  restoreCodeEditorStateFromSaved,
} from '@webapp/utils/editor-project';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import { ModuleId } from '@lib/robo/types';

export const useEditor = (editorType: EditorType) => {
  const dispatch = useDispatch();

  const editor: CodeEditorState | LiveEditorState = useSelector((state: RootState) => state.webapp[editorType].editor);
  const widgetsModulesIdsMap = selectWidgetsModulesIdsMap(editor);
  const { connectedModules } = useRobo();

  let addWidget;
  let _setEditorState;
  let _resetEditorState;
  let removeWidget;
  let updateWidgetProps;
  let updateWidgetData;
  let _setWidgetsPlacement;
  let _removeAllWidgets;
  let _setPlayButtonIsBlocked;
  let _setIsPlaying;
  let _prepareStateForSave;
  let _restoreStateFromSaved;
  let _getUsedModulesIds;

  const getWidgetModulesIds = useCallback(
    (widgetId: string) => {
      return widgetsModulesIdsMap[widgetId] ?? [];
    },
    [widgetsModulesIdsMap]
  );

  const isWidgetModulesConnected = useCallback(
    (widgetId: string) => {
      return getWidgetModulesIds(widgetId).every(moduleId => {
        return connectedModules.find(({ id }) => id === moduleId);
      });
    },
    [connectedModules, getWidgetModulesIds]
  );

  const usedAndConnectedModulesIds = useMemo(() => {
    return [...new Set(Object.values(widgetsModulesIdsMap).flatMap(modules => modules))]
      .sort()
      .filter(id => connectedModules.find(cm => cm.id === id));
  }, [widgetsModulesIdsMap, connectedModules]);

  const getWidgetsByModuleIds = (moduleIds: ModuleId[]) => {
    return Object.entries(editor.widgets)
      .filter(([_, widget]) => {
        return (
          moduleIds.length === widget.data?.moduleIds.length &&
          widget.data?.moduleIds.every(id => moduleIds.includes(id as ModuleId))
        );
      })
      .map(value => value[1]);
  };

  if (editorType === EditorType.Live) {
    // ! ==== LIVE EDITOR ============================================================================================================
    addWidget = (payload: AddWidgetPayload) => dispatch(LIVE_addWidget(payload));

    _setEditorState = (payload: SetLiveEditorStatePayload) => {
      return dispatch(LIVE_setEditorState(payload));
    };

    _resetEditorState = () => {
      return dispatch(LIVE_resetEditorState());
    };

    removeWidget = (payload: RemoveWidgetPayload) => dispatch(LIVE_removeWidget(payload));

    updateWidgetProps = (id: string, props: Partial<WidgetProps>) => dispatch(LIVE_updateWidgetProps({ id, props }));

    updateWidgetData = (id: string, data: Partial<WidgetData>) => dispatch(LIVE_updateWidgetData({ id, data }));

    _setWidgetsPlacement = (placement: LiveEditorState['runtime']['widgetsPlacement']) =>
      dispatch(LIVE_setWidgetsPlacement(placement));
    _removeAllWidgets = () => dispatch(LIVE_removeAllWidgets());
    _setPlayButtonIsBlocked = (isBlocked: boolean) => dispatch(LIVE_setPlayButtonIsBlocked(isBlocked));
    _setIsPlaying = (isPlaying: boolean) => dispatch(LIVE_setIsPlaying(isPlaying));
    _prepareStateForSave = prepareLiveEditorStateForSave;
    _restoreStateFromSaved = restoreLiveEditorFromSaved;

    _getUsedModulesIds = () => {
      return COMMON_selectUsedModulesIds(editor);
    };
  } else if (editorType === EditorType.Code) {
    // ! ==== CODE EDITOR ============================================================================================================
    _prepareStateForSave = prepareCodeEditorStateForSave;

    _restoreStateFromSaved = restoreCodeEditorStateFromSaved;

    _setEditorState = (payload: SetCodeEditorStatePayload) => {
      return dispatch(CODE_setEditorState(payload));
    };

    _resetEditorState = () => {
      return dispatch(CODE_resetEditorState());
    };

    addWidget = (payload: AddWidgetPayload) => dispatch(CODE_addWidget(payload));

    removeWidget = (payload: RemoveWidgetPayload) => dispatch(CODE_removeWidget(payload));

    updateWidgetProps = (id: string, props: Partial<WidgetProps>) => dispatch(CODE_updateWidgetProps({ id, props }));

    updateWidgetData = (id: string, data: Partial<WidgetData>) => dispatch(CODE_updateWidgetData({ id, data }));

    _setWidgetsPlacement = () => console.warn('setWidgetsPlacement is not available in code editor');

    _removeAllWidgets = () => dispatch(CODE_removeAllWidgets());

    _setPlayButtonIsBlocked = (isBlocked: boolean) => dispatch(CODE_setPlayButtonIsBlocked(isBlocked));

    _setIsPlaying = (isPlaying: boolean) => dispatch(CODE_setIsPlaying(isPlaying));

    _getUsedModulesIds = () => {
      return COMMON_selectUsedModulesIds(editor);
    };
  } else if (editorType === EditorType.Blockly) {
    throw new Error(`Blockly editor is not supported yet`);
  } else {
    throw new Error(`Editor type not supported: ${editorType}`);
  }

  return {
    ...editor,
    ...editor.runtime, // destructuring runtime properties for backward compatibility
    state: editor,
    prepareStateForSave: _prepareStateForSave,
    restoreStateFromSaved: _restoreStateFromSaved,
    setEditorState: _setEditorState,
    resetEditorState: _resetEditorState,
    addWidget,
    removeWidget,
    updateWidgetProps,
    updateWidgetData,
    setWidgetsPlacement: _setWidgetsPlacement,
    removeAllWidgets: _removeAllWidgets,
    setPlayButtonIsBlocked: _setPlayButtonIsBlocked,
    setIsPlaying: _setIsPlaying,
    getUsedModulesIds: _getUsedModulesIds,
    getWidgetModulesIds,
    isWidgetModulesConnected,
    usedAndConnectedModulesIds,
    getWidgetsByModuleIds,
  };
};
