import { DeviceType, FirmwareMap } from '@tools/types/firmwareTypes';

export const BASE_FIRMWARE_URL = 'https://devices.robowunderkind.com/firmware/';

export const ROBO_DEVICE_VENDOR_ID = 6790;

export const ESP_DEVICE_BAUD_RATE = 115200;
export const ESP_DEVICE_FILTERS = [
  // return back in a couple of moths. When most of our devices will update firmware (today is 05 of April 2024).
  // {
  //   usbVendorId: ROBO_DEVICE_VENDOR_ID,
  // },
];

export const DEVICE_LABELS: { [key in DeviceType]: string } = {
  RW4: 'Main Block',
  bridge: 'Bridge',
};

export const firmwaresConfiguration: FirmwareMap = {
  bridge: {
    bootloaderFilename: 'Robo_Bridge_bootloader.bin',
    bootloaderOffset: '0x1000',
    partitionTableFilename: 'Robo_Bridge_partition-table.bin',
    partitionTableOffset: '0x8000',
    latestFirmwareFilename: 'latest',
    firmwareOffset: '0x10000',
    latestVersionInfoFilename: 'latest.txt',
    firmwareVersionRegexp: `^Robo_Bridge_(\\d+)_(\\d+)_(\\d+)\\.bin$`,
    channels: {
      dev: {
        directory: 'robo-bridge-dev/',
      },
      prod: {
        directory: 'robo-bridge/',
      },
    },
  },
  RW4: {
    bootloaderFilename: 'RW4_bootloader.bin',
    bootloaderOffset: '0x1000',
    partitionTableFilename: 'RW4_partition-table.bin',
    partitionTableOffset: '0x8000',
    latestFirmwareFilename: 'latest',
    firmwareOffset: '0x10000',
    latestVersionInfoFilename: 'latest.txt',
    firmwareVersionRegexp: `^RW4_(\\d+)_(\\d+)_(\\d+)\\.bin$`,
    channels: {
      dev: {
        directory: 'main-block-4-dev/',
      },
      prod: {
        directory: 'main-block-4/',
      },
    },
  },
};
