import React, { useEffect, useMemo, useState } from 'react';
import { cloneDeep, debounce } from 'lodash';

import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';

import { LedDisplay } from '@lib/robo/modules';
import { ModuleId } from '@lib/robo/types';
import Slider from '@webapp/components/ui/sliders/slider';
import { LedPixelMatrixComponent } from '@webapp/components/blocks/component/led-pixel-matrix-component';
import {
  convertToTwoDimensionalArray,
  createInitialMatrixState,
  rotateOneDimensionalMatrix,
} from '@webapp/components/blocks/widgets/led-pixel-display-widget/utils';
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ClearIcon,
  CodeRotateIcon,
  PlayIcon,
  PlusIcon,
  RepeatIcon,
  ReverseIcon,
  SpeedIcon,
} from '@webapp/components/icons';
import { colors } from '@themes/config/theme-colors';
import { LedPixelMatrixComponentPreview } from '@webapp/components/blocks/component/led-pixel-matrix-component-preview';
import { ExecutableActionWidgetComponent, ActionWidgetExecutionResult, WidgetExecutionType } from '@webapp/store/types';
import { AbortablePromise } from '@lib/utils/abortable-promise';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';
import IconWithText from '@webapp/components/ui/icon-with-text';
import RoundToggleIconButton from '@webapp/components/ui/buttons/round-toggle-icon-button';
import { lookingFaceFirstFrame, smilingFaceFirstFrame, laughingFaceFirstFrame } from './predefined-animations';
import RoundIconButton from '@webapp/components/ui/buttons/round-icon-button';

const PredefinedAnimations: Array<Animation> = [
  {
    code: LedDisplay.Animations.LookingFace,
    isPredefined: true,
    frames: [lookingFaceFirstFrame],
  },
  {
    code: LedDisplay.Animations.SmilingFace,
    isPredefined: true,
    frames: [smilingFaceFirstFrame],
  },
  {
    code: LedDisplay.Animations.LaughingFace,
    isPredefined: true,
    frames: [laughingFaceFirstFrame],
  },
];

const widgetConfig = {
  maximumAnimationsCount: 10,
  animationFramesCount: 5,
  minRepeatCount: 1,
  maxRepeatCount: 10,
  minAnimationSpeed: 1,
  maxAnimationSpeed: 10,
  updateWidgetDataDelay: 300,
} as const;

const mapSpeedToFrameRate = (speed: number) => {
  return 1000 / speed;
};

const CodeAnimateWidget: ExecutableActionWidgetComponent<AnimateWidgetData> = ({ id }) => {
  const { getWidgetById, updateWidgetData: _updateWidgetData } = useCodeEditor();
  const { client: roboClient } = useRobo();

  const updateWidgetData: typeof _updateWidgetData<AnimateWidgetData> = useMemo(
    () =>
      debounce((id, data) => {
        _updateWidgetData<AnimateWidgetData>(id, data);
      }, widgetConfig.updateWidgetDataDelay), // Adjust the debounce delay as needed
    [id]
  );

  const widget = getWidgetById<AnimateWidgetData>(id);
  const widgetData = widget?.data;

  const size = LedDisplay.DisplaySize;
  const [animations, setAnimations] = useState<Array<Animation>>(
    widgetData?.animations ?? CodeAnimateWidget.initialData.animations
  );

  const [activeAnimationIndex, setActiveAnimationIndex] = useState<number>(
    widgetData?.activeAnimationIndex ?? CodeAnimateWidget.initialData.activeAnimationIndex
  );
  const [isPredefinedAnimation, setIsPredefinedAnimation] = useState<boolean>(
    widgetData?.isPredefinedAnimation ?? CodeAnimateWidget.initialData.isPredefinedAnimation
  );
  const [activeAnimationFrameIndex, setActiveAnimationFrameIndex] = useState<number>(0);
  const [rotation, setRotation] = useState<number>(widgetData?.rotation ?? CodeAnimateWidget.initialData.rotation);
  const [repeatCount, setRepeatCount] = useState<number>(
    widgetData?.repeatCount ?? CodeAnimateWidget.initialData.repeatCount
  );
  const [animationSpeed, setAnimationSpeed] = useState<number>(
    widgetData?.animationSpeed ?? CodeAnimateWidget.initialData.animationSpeed
  );
  const [isReversed, setIsReversed] = useState<boolean>(
    widgetData?.isReversed ?? CodeAnimateWidget.initialData.isReversed
  );
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const { model: roboModel } = useRobo();

  const moduleId = widgetData?.moduleIds[0] as ModuleId;
  const MODULE = roboModel?.modules.ledDisplays[moduleId];

  const handleClear = () => {
    setAnimations(prev => {
      const currentAnimation = cloneDeep(prev[activeAnimationIndex]);
      currentAnimation.frames[activeAnimationFrameIndex] = createInitialMatrixState(size);

      return [...prev.slice(0, activeAnimationIndex), currentAnimation, ...prev.slice(activeAnimationIndex + 1)];
    });
    sendMatrixToLedDisplay(createInitialMatrixState(size), rotation);
  };

  const handleRotate = () => {
    const nextRotation = (rotation + 90) % 360;
    setRotation(nextRotation);
    updateWidgetData(id, { rotation: nextRotation });
    sendMatrixToLedDisplay(allAnimations[activeAnimationIndex].frames[activeAnimationFrameIndex], nextRotation);
  };

  const handleAdd = () => {
    const nextAnimations = [...animations, createInitialAnimation()];
    const nextIndex = nextAnimations.length - 1;
    setAnimations(nextAnimations);
    setActiveAnimationIndex(nextIndex);
    updateWidgetData(id, { activeAnimationIndex: nextIndex });
  };

  const handleReverse = () => {
    setIsReversed(prev => !prev);
    updateWidgetData(id, { isReversed: !isReversed });
  };

  const canAddMoreDrawings = animations.length < widgetConfig.maximumAnimationsCount;

  const createRemoveHandler = (index: number) => () => {
    const nextAnimations = [...animations.slice(0, index), ...animations.slice(index + 1)];
    const nextIndex = Math.min(index, nextAnimations.length - 1);
    setActiveAnimationIndex(nextIndex);
    setAnimations(nextAnimations);
  };

  useEffect(() => {
    updateWidgetData(id, { animations: animations });
  }, [id, animations]);

  useEffect(() => {
    return () => {
      roboClient?.setRunCommand(false);
    };
  }, [roboClient]);

  const allAnimations = [...animations, ...PredefinedAnimations];

  const handlePlay = () => {
    setIsPlaying(true);

    const animation = allAnimations[activeAnimationIndex];

    if (animation.isPredefined) {
      MODULE?.setAnimationAction(
        animation.code,
        repeatCount,
        isReversed ? 1 : 0,
        LedDisplay.convertAngleToOrientation(rotation),
        0,
        () => {
          setIsPlaying(false);
        }
      );
    } else {
      // todo: maybe skip empty frames
      MODULE?.setAnimationAction(
        animation.frames.map(frame => convertToTwoDimensionalArray(frame, size)),
        repeatCount,
        isReversed ? 1 : 0,
        LedDisplay.convertAngleToOrientation(rotation),
        mapSpeedToFrameRate(animationSpeed),
        () => {
          setIsPlaying(false);
        }
      );
    }
  };

  const handleStop = () => {
    setIsPlaying(false);
    roboClient?.setRunCommand(false);
    sendMatrixToLedDisplay(allAnimations[activeAnimationIndex].frames[activeAnimationFrameIndex], rotation);
  };

  const sendMatrixToLedDisplay = (matrix: Array<boolean>, rotation: number) => {
    if (!MODULE) {
      return;
    }
    const twoDimMatrix = convertToTwoDimensionalArray(rotateOneDimensionalMatrix(matrix, size, rotation), size);
    MODULE.setImage(twoDimMatrix);
  };

  // this hook is executed when component is mounted and when activeAnimationFrameIndex changes
  useEffect(() => {
    sendMatrixToLedDisplay(allAnimations[activeAnimationIndex].frames[activeAnimationFrameIndex], rotation);
  }, [activeAnimationFrameIndex]);

  return (
    <Box
      sx={{
        width: '600px',
      }}
    >
      <Grid container spacing={0} sx={{ paddingLeft: '0px' }}>
        {/* Play controls */}
        <Grid
          item
          xs={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: '10px',
            paddingBottom: '10px',
            gap: '16px',
          }}
        >
          {isPlaying ? (
            <RoundIconButton
              icon={<StopIcon sx={{ width: 25, height: 25 }} htmlColor="#5A418B" />}
              disabled={!MODULE}
              onClick={handleStop}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Stop
                </Typography>
              }
              mainColor="#5A418B"
              secondaryColor="#E9E9E9"
              tertiaryColor="#FFFFFF"
            />
          ) : (
            <RoundIconButton
              icon={<PlayIcon sx={{ width: 15, height: 15 }} htmlColor="#5A418B" />}
              disabled={!MODULE}
              onClick={handlePlay}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Play
                </Typography>
              }
              mainColor="#5A418B"
              secondaryColor="#E9E9E9"
              tertiaryColor="#FFFFFF"
            />
          )}
          <RoundIconButton
            icon={<ClearIcon sx={{ width: 28, height: 28 }} />}
            disabled={isPlaying}
            onClick={handleClear}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Clear
              </Typography>
            }
            mainColor="#5A418B"
            secondaryColor="#E9E9E9"
            tertiaryColor="#FFFFFF"
          />
          <RoundIconButton
            icon={<CodeRotateIcon sx={{ width: 25, height: 25 }} />}
            disabled={isPlaying}
            onClick={handleRotate}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Rotate
              </Typography>
            }
            mainColor="#5A418B"
            secondaryColor="#E9E9E9"
            tertiaryColor="#FFFFFF"
          />
          <RoundToggleIconButton
            icon={<ReverseIcon sx={{ width: 42, height: 42 }} />}
            onChange={handleReverse}
            disabled={isPlaying}
            value={isReversed}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B">
                Reverse
              </Typography>
            }
            mainColor="#5A418B"
            secondaryColor="#E9E9E9"
            tertiaryColor="#FFFFFF"
          />
        </Grid>

        {/* Matrix */}
        <Grid
          item
          xs={6}
          sx={{
            paddingTop: '10px',
            paddingLeft: '20px',
          }}
        >
          <Grid item xs={12}>
            <LedPixelMatrixComponent
              size={size}
              sx={{
                // this fix is needed for Safari, oherwise it calculates height in this
                // particular layout not properly
                height: 'initial',
              }}
              matrix={allAnimations[activeAnimationIndex].frames[activeAnimationFrameIndex]}
              disabled={allAnimations[activeAnimationIndex].isPredefined || isPlaying}
              theming="yellow"
              onMatrixChanged={matrix => {
                setAnimations(prev => {
                  const currentAnimation = cloneDeep(prev[activeAnimationIndex]);
                  currentAnimation.frames[activeAnimationFrameIndex] = matrix;

                  return [
                    ...prev.slice(0, activeAnimationIndex),
                    currentAnimation,
                    ...prev.slice(activeAnimationIndex + 1),
                  ];
                });
              }}
              onDragEnd={matrix => {
                sendMatrixToLedDisplay(matrix, rotation);
              }}
            />
          </Grid>

          {/* PREVIEW */}
          <Grid item xs={12}>
            <Box
              sx={{
                overflowY: 'auto',
                padding: '16px 0px',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '7px',
                  alignItems: 'center',
                }}
              >
                <Tooltip title={canAddMoreDrawings ? '' : `Maximum is ${widgetConfig.maximumAnimationsCount} drawings`}>
                  <span>
                    <IconButton
                      onClick={handleAdd}
                      disabled={!canAddMoreDrawings || isPlaying}
                      sx={{
                        width: '60px',
                        height: '60px',
                        borderRadius: '5px',
                        background: colors.black['050'],
                        marginRight: '3px',
                      }}
                    >
                      <PlusIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                {allAnimations.map((animation, index) => (
                  <LedPixelMatrixComponentPreview
                    key={index}
                    size={size}
                    theming="yellow"
                    matrix={animation.frames[0]}
                    selected={activeAnimationIndex === index}
                    disabled={isPlaying}
                    onClick={() => {
                      setActiveAnimationIndex(index);
                      setActiveAnimationFrameIndex(0);
                      setIsPredefinedAnimation(animation.isPredefined);
                      updateWidgetData(id, {
                        activeAnimationIndex: index,
                        isPredefinedAnimation: animation.isPredefined,
                      });
                      sendMatrixToLedDisplay(animation.frames[0], rotation);
                    }}
                    onRemove={
                      animations.length === 1 || animation.isPredefined ? undefined : createRemoveHandler(index)
                    }
                  />
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Frame controls */}
        <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '10px' }}>
          <Typography
            variant="x-body-bold"
            color="#5A418B"
            sx={{
              marginBottom: '33px',
            }}
          >
            {activeAnimationFrameIndex + 1} of {allAnimations[activeAnimationIndex].frames.length}
          </Typography>
          <RoundIconButton
            icon={<ArrowBackIcon sx={{ width: 25, height: 25 }} />}
            disabled={activeAnimationFrameIndex === 0 || allAnimations[activeAnimationIndex].isPredefined || isPlaying}
            onClick={() => {
              const nextIndex = activeAnimationFrameIndex - 1;
              setActiveAnimationFrameIndex(nextIndex);
            }}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B" sx={{ whiteSpace: 'nowrap' }}>
                Previous frame
              </Typography>
            }
            mainColor="#5A418B"
            secondaryColor="#E9E9E9"
            tertiaryColor="#FFFFFF"
          />
          <RoundIconButton
            icon={<ArrowForwardIcon sx={{ width: 25, height: 25 }} />}
            disabled={
              activeAnimationFrameIndex === allAnimations[activeAnimationIndex].frames.length - 1 ||
              allAnimations[activeAnimationIndex].isPredefined ||
              isPlaying
            }
            onClick={() => {
              const nextIndex = activeAnimationFrameIndex + 1;
              setActiveAnimationFrameIndex(nextIndex);
            }}
            text={
              <Typography variant="x-tiny-bold" color="#5A418B" sx={{ whiteSpace: 'nowrap' }}>
                Next frame
              </Typography>
            }
            sx={{
              marginTop: '28px',
            }}
            mainColor="#5A418B"
            secondaryColor="#E9E9E9"
            tertiaryColor="#FFFFFF"
          />
        </Grid>

        {/* Right panel with sliders */}
        <Grid
          item
          xs={3}
          spacing={1}
          sx={{
            display: 'flex',
            gap: '16px',
            boxShadow: '0px 2px 19px rgba(0, 0, 0, 0.30)',
            clipPath: 'inset(0px 0px 0px -20px)',
            marginTop: '-10px',
            marginBottom: '-10px',
            marginRight: '-20px',
            paddingTop: '20px',
            paddingBottom: '20px',
            paddingLeft: '22px',
            paddingRight: '24px',
          }}
        >
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconWithText
              sx={{
                marginBottom: '42px',
              }}
              icon={<RepeatIcon sx={{ fontSize: 'inherit', width: 30, height: 30 }} />}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Repeat
                </Typography>
              }
            />
            <Slider
              value={repeatCount}
              valueLabelDisplay="on"
              maxAsInfinite={false}
              minAsInfinite={false}
              max={widgetConfig.maxRepeatCount}
              min={widgetConfig.minRepeatCount}
              step={1}
              disabled={isPlaying}
              onChange={(_, value) => {
                if (typeof value !== 'number') {
                  return;
                }
                setRepeatCount(value);
                updateWidgetData(id, { repeatCount: value });
              }}
              orientation="vertical"
              sx={{ height: '100%', width: '30px' }}
              mainColor="#FEC84B"
              railColor="#E9E9E9"
              labelColor="#5A418B"
              appearance="default"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconWithText
              sx={{
                marginBottom: '42px',
              }}
              icon={<SpeedIcon sx={{ fontSize: 'inherit', width: 30, height: 30 }} />}
              text={
                <Typography variant="x-tiny-bold" color="#5A418B">
                  Speed
                </Typography>
              }
            />
            <Slider
              value={animationSpeed}
              valueLabelDisplay="on"
              maxAsInfinite={false}
              minAsInfinite={false}
              max={widgetConfig.maxAnimationSpeed}
              min={widgetConfig.minAnimationSpeed}
              step={1}
              disabled={allAnimations[activeAnimationIndex].isPredefined || isPlaying}
              onChange={(_, value) => {
                if (typeof value !== 'number') {
                  return;
                }
                setAnimationSpeed(value);
                updateWidgetData(id, { animationSpeed: value });
              }}
              orientation="vertical"
              sx={{ height: '100%', width: '30px' }}
              mainColor="#FEC84B"
              railColor="#E9E9E9"
              labelColor="#5A418B"
              appearance="default"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

CodeAnimateWidget.execute = async ({ signal, roboModel, widgetId, getWidgetById }) => {
  return AbortablePromise<ActionWidgetExecutionResult>(signal, async (resolve, reject) => {
    const widget = getWidgetById(widgetId);

    if (!widget) {
      throw new Error('Widget not found');
    }

    const widgetData = widget.data;
    const moduleId = widgetData.moduleIds[0] as ModuleId;
    const { animations, repeatCount, animationSpeed, isReversed, activeAnimationIndex, rotation } = widgetData;

    const allAnimations = [...animations, ...PredefinedAnimations];

    if (!moduleId || !roboModel) {
      throw new Error('Module or RoboModel not found');
    }

    if (animations.length === 0) {
      throw new Error('No animations available');
    }
    const MODULE = roboModel.modules.ledDisplays[moduleId];
    if (!MODULE) {
      throw new Error('Module not found');
    }

    const animation = allAnimations[activeAnimationIndex];
    if (!animation) {
      throw new Error('Animation not found');
    }

    const handleResult = ({ isError }: { isError: boolean }) => {
      if (isError) {
        reject(new Error('Error setting motor action'));
      } else {
        resolve({ widgetId: widget.id, resolved: true, type: WidgetExecutionType.Action });
      }
    };

    if (animation.isPredefined) {
      MODULE.setAnimationAction(
        animation.code,
        repeatCount,
        isReversed ? 1 : 0,
        LedDisplay.convertAngleToOrientation(rotation),
        0,
        handleResult
      );
    } else {
      MODULE.setAnimationAction(
        animation.frames.map(frame => convertToTwoDimensionalArray(frame, LedDisplay.DisplaySize)),
        repeatCount,
        isReversed ? 1 : 0,
        LedDisplay.convertAngleToOrientation(rotation),
        mapSpeedToFrameRate(animationSpeed),
        handleResult
      );
    }
  });
};

type AnimationBase = {
  isPredefined: boolean;
  frames: Array<Array<boolean>>;
};

type PredefinedAnimation = AnimationBase & {
  isPredefined: true;
  code: number;
};

type CustomAnimation = AnimationBase & {
  isPredefined: false;
  code?: never;
};

type Animation = PredefinedAnimation | CustomAnimation;

type AnimateWidgetData = {
  animations: Array<Animation>;
  repeatCount: number;
  animationSpeed: number;
  isReversed: boolean;
  activeAnimationIndex: number;
  isPredefinedAnimation: boolean;
  rotation: number;
};

const createInitialAnimation = (): CustomAnimation => {
  return {
    isPredefined: false,
    frames: new Array(widgetConfig.animationFramesCount)
      .fill(null)
      .map(() => createInitialMatrixState(LedDisplay.DisplaySize)),
  };
};

CodeAnimateWidget.initialData = {
  animations: [createInitialAnimation()],
  repeatCount: 1,
  animationSpeed: 1,
  isReversed: false,
  activeAnimationIndex: 0,
  isPredefinedAnimation: false,
  rotation: 0,
};

export default CodeAnimateWidget;
