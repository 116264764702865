import { RoboClient } from '@lib/robo/robo-client';
import { MODULE_MAPPINGS } from '@lib/robo/module-mappings';
import { ModulesCollectionTypes, HandlerType } from '@lib/robo/types';

// todo Remove type definitions out of this file
export interface IRoboStateModule {
  type: string;
  id: string;
  data?: object;
}

export interface IRoboState {
  model: {
    [key: string]: {
      [key: string]: never;
    };
  };
}

/**
 * The type of the onActionDone callback.
 * @param isError - Whether the action is an error.
 * @param targetId - The id of the target.
 */
export type onActionDoneType = ({ isError, targetId }: { isError: boolean; targetId: string }) => void;

export interface IRoboStore {
  addModule(module: IRoboStateModule): void;

  updateModule(module: IRoboStateModule): void;

  removeModule(module: { type: string; id: string }): void;

  getState(): IRoboState;
}

export abstract class BaseModule<T extends { initialDataState: () => any }> {
  public readonly id: string;
  protected client: RoboClient;
  private enabled = false;
  public readonly index: number;
  private store: IRoboStore;
  public readonly type: ModulesCollectionTypes;

  protected triggerIdOffset: number;

  /**
   * Constructs a new instance of the BaseModule class.
   *
   * @param {string} id - The id of the module.
   * @param {RoboClient} client - The RoboClient instance.
   * @param {string} type - The type of the module. (Please specify what the possible values for 'type' are.)
   * @param {any} store - The store object.
   * @param {number} triggerIdOffset - The offset for the trigger id.
   */
  constructor(id: string, client: RoboClient, type: ModulesCollectionTypes, store: IRoboStore) {
    this.id = id;
    this.client = client;
    this.store = store;
    this.type = type;
    this.triggerIdOffset = MODULE_MAPPINGS[type].triggerIdOffset || NaN;

    // index is usually the last part of the id
    const index = parseInt(id.split('_').pop() || '0', 10);
    this.index = isNaN(index) ? -1 : index - 1;
  }

  execute(): void {
    return undefined;
  }

  enable(): void {
    this.enabled = true;
    this.onEnable();
  }

  disable(): void {
    this.enabled = false;
    this.onDisable();
  }

  isEnabled(): boolean {
    return this.enabled;
  }

  onEnable(): void {
    console.debug(`Module ${this.id} with index ${this.index} enabled`, this);
    const initialData = (this.constructor as typeof BaseModule).initialDataState();

    this.store &&
      this.store.addModule({
        type: this.type || 'UNKNOWN',
        id: this.id,
        data: {
          index: this.index + 1,
          ...initialData,
        },
      });
  }

  updateDataState(state: object): void {
    this.store &&
      this.store.updateModule({
        type: this.type || 'UNKNOWN',
        id: this.id,
        data: {
          ...state,
        },
      });
  }

  getDataState(): ReturnType<T['initialDataState']> {
    return ((this.store && this.store.getState().model[this.type][this.id]) as ReturnType<T['initialDataState']>) || ({} as ReturnType<T['initialDataState']>);
  }

  onDisable(): void {
    console.debug(`Module ${this.id} with index ${this.index} disabled`);
    this.store &&
      this.store.removeModule({
        type: this.type || 'UNKNOWN',
        id: this.id,
      });
  }

  /**
   * Generates an action or trigger id based on the type and module index.
   */
  generateActionOrTriggerId(): number {
    if (isNaN(this.triggerIdOffset)) {
      console.error(`TriggerIdOffset is not set for module ${this.id}`);
      return this.index;
    }

    return this.triggerIdOffset + this.index;
  }

  /**
   * Returns the initial data state of the module.
   */
  static initialDataState() {
    const data = {} as const;

    return data;
  }

  /**
   * Subscribes to the response of an action or trigger.
   * @param actionOrTriggerId - The id of the action or trigger.
   * @param onActionDone - The callback to call when the action or trigger is done.
   * @returns The unsubscribe function.
   */
  subscribeToResponse(
    actionOrTriggerId: number,
    onActionDone: ({ isError, targetId }: { isError: boolean; targetId: string }) => void
  ) {
    const unsubscribe = this.client.registerHandler(
      HandlerType.OnActionOrTriggerResponse,
      ({ isError, targetId }) => {
        console.debug('[EXEC] ACTION RESPONSE', { isError, targetId });
        onActionDone({ isError, targetId });
        unsubscribe();
      },
      `${actionOrTriggerId}`
    );

    return unsubscribe;
  }
}

// Define a type that infers the return type of initialDataState
export type InitialDataStateType<T extends typeof BaseModule> = ReturnType<T['initialDataState']>;
