import React from 'react';

import { styled } from '@mui/system';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    width: '50px',
    color: 'white',
    border: 'none',
    [`&.Mui-selected`]: {
      color: 'white',
      fontWeight: 'bolder',
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
  },
  [`& .${toggleButtonGroupClasses.lastButton}`]: {
    borderTopRightRadius: '19px',
    borderBottomRightRadius: '19px',
  },
  [`& .${toggleButtonGroupClasses.firstButton}`]: {
    borderTopLeftRadius: '19px',
    borderBottomLeftRadius: '19px',
  },
}));

const MotorDirectionToggleButton = ({ onChange, value }) => {
  return (
    <StyledToggleButtonGroup
      color="secondary"
      value={value}
      exclusive
      onChange={onChange}
      size="small"
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.3011)',
        height: '38px',
        borderRadius: '19px',
        width: '76px',
        marginTop: '30px',
      }}
    >
      <ToggleButton value="left">L</ToggleButton>
      <ToggleButton value="right">R</ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export default MotorDirectionToggleButton;
