import * as Sentry from '@sentry/gatsby';

import { MQTTClient } from '@lib/mqtt/mqtt-client';
import { RoboClient } from '@lib/robo/robo-client';
import { roboClientManager } from '@lib/robo/robo-client-manager';

import {
  connect as bridgeConnect,
  connecting as bridgeConnecting,
  connected as bridgeConnected,
  connectionLost as bridgeConnectionLost,
  connectionFailure as bridgeConnectionFailure,
  disconnect as bridgeDisconnect,
  disconnected as bridgeDisconnected,
  roboListUpdated as bridgeRoboListUpdated,
  setFirmware as bridgeSetFirmware,
  setIP as bridgeSetIP,
  setWifiSecurityType,
} from '../slices/devices/bridge.slice';

import {
  connect as roboConnect,
  connected as roboConnected,
  disconnect as roboDisconnect,
  disconnected as roboDisconnected,
} from '../slices/devices/robo.slice';

import { reset as resetModel } from '../slices/model/model.slice';

let mqttClient;

export const getMqttClient = () => {
  return mqttClient;
};

/**
 * Middleware for handling device-related actions.
 *
 * @param {Object} store - The Redux store.
 * @returns {Function} - The next middleware function.
 */
export const devicesMiddleware = store => next => async action => {
  const { dispatch, getState } = store;

  const bridge = getState().webapp.devices.bridge;
  const robo = getState().webapp.devices.robo;

  let fetchedRoboList;

  switch (action.type) {
    // BRIDGE
    // -- Connect to the bridge
    case bridgeConnect.type:
      handleConnectToBridge(dispatch, { bridgeId: bridge.id });
      break;

    // -- Add proper subscription when bridge is connected
    case bridgeConnected.type:
      handleSubscribeBridgeEvents(dispatch);
      break;

    case bridgeDisconnect.type:
      handleDisconnectFromBridge(dispatch);
      break;

    case bridgeDisconnected.type:
      // Disconnect from all robos
      dispatch(roboDisconnect());
      break;

    // -- Robo list updated. If the current robo is not in the list, disconnect from it.
    case bridgeRoboListUpdated.type:
      fetchedRoboList = action.payload;
      if (robo.id && !fetchedRoboList.find(fetchedRobo => fetchedRobo.name === robo.id)) {
        dispatch(roboDisconnect());
      }

      break;

    // ROBO
    //  -- Connect to a robo
    case roboConnect.type:
      handleConnectToRobo(dispatch, action.payload);
      break;

    case roboDisconnect.type:
      handleDisconnectFromRobo(dispatch, { roboId: robo.id });
      break;

    case roboDisconnected.type:
      dispatch(resetModel());
      break;

    default:
      break;
  }

  return next(action);
};

const addSentryBreadcrumb = message => {
  Sentry.addBreadcrumb({
    category: 'devices',
    message: message,
    level: 'info',
  });
};

/**
 * Connects to a bridge using the provided bridge ID and initializes an MQTT client.
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {Object} options - The options object.
 * @param {string} options.bridgeId - The ID of the bridge to connect to.
 */
const handleConnectToBridge = async (dispatch, { bridgeId }) => {
  dispatch(bridgeConnecting());

  addSentryBreadcrumb(`Connecting to the bridge: ${bridgeId}`);
  mqttClient = await initMqttClient(bridgeId);

  mqttClient.onConnected(() => {
    addSentryBreadcrumb(`Connected to the bridge: ${bridgeId}`);
    dispatch(bridgeConnected());
  });

  mqttClient.onConnectionLost(() => {
    addSentryBreadcrumb(`Connection lost to the bridge: ${bridgeId}`);
    dispatch(bridgeConnectionLost());
  });

  mqttClient.onConnectionFailure(() => {
    addSentryBreadcrumb(`Connection failure to the bridge: ${bridgeId}`);
    dispatch(bridgeConnectionFailure());
  });

  mqttClient.onDisconnected(() => {
    addSentryBreadcrumb(`Disconnected from the bridge: ${bridgeId}`);
    dispatch(bridgeDisconnected());
  });

  mqttClient.onMessageArrived((client, message) => {
    if (message.topic === 'robo/list') {
      const roboList = parseRoboList(message.payloadString);
      dispatch(bridgeRoboListUpdated(roboList));
    }
    // TODO: "frimware" because it is typo on device
    if (message.topic === 'bridge/frimware' || message.topic === 'bridge/firmware') {
      dispatch(bridgeSetFirmware(message.payloadString));
    }
    if (message.topic === 'bridge/ip') {
      dispatch(bridgeSetIP(message.payloadString));
    }
    if (message.topic === 'bridge/wifi') {
      dispatch(setWifiSecurityType(message.payloadString));
    }
  });

  mqttClient.connect();
};

/**
 * Handles the disconnection from the MQTT broker.
 * @param {function} dispatch - The Redux dispatch function.
 */
const handleDisconnectFromBridge = dispatch => {
  mqttClient.disconnect();
  dispatch(bridgeDisconnected());
};

/**
 * Subscribes to MQTT events and publishes a message to retrieve a list of robos.
 * @param {function} dispatch - The Redux dispatch function.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleSubscribeBridgeEvents = _dispatch => {
  mqttClient.subscribe('robo/list');
  mqttClient.subscribe('bridge/firmware');
  mqttClient.subscribe('bridge/ip');
  mqttClient.subscribe('bridge/wifi');
  mqttClient.publish('robo/list/get', 'STATUS_LIST'); // ALIAS_LIST, STATUS_LIST
  mqttClient.publish('bridge/update', getBridgeVersionPayload()); // get bridge firmware version
  mqttClient.publish('bridge/ip', getBridgeIPPayload());
  mqttClient.publish('bridge/wifi', getBridgeWifiSecurityTypePayload());
};

export const getBridgeVersionPayload = () => new Uint8Array([0]);
export const getBridgeUpdatePayload = () => new Uint8Array([0x55]);
export const getBridgeIPPayload = () => new Uint8Array([0x01]);
export const getBridgeWifiSecurityTypePayload = () => new Uint8Array([0x01]);

/**
 * Connects to a Robo device using the provided MQTT client and dispatches a `roboConnect` action.
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {Object} options - The options object.
 * @param {string} options.id - The ID of the Robo device to connect to.
 */
const handleConnectToRobo = (dispatch, { id, alias, batchCheckTimeout }) => {
  addSentryBreadcrumb(`Connect to the MainBlock: ${id}. batchCheckTimeout: ${batchCheckTimeout}`);
  const roboClient = new RoboClient(mqttClient, id, {
    batchCheckTimeout,
  });

  // Register the client with the manager
  roboClientManager.addClient(id, roboClient);

  dispatch(
    roboConnected({
      id: id,
      name: id,
      alias: alias,
    })
  );
};

/**
 * Handles disconnection from a Robo device.
 * @param {Function} dispatch - The Redux dispatch function.
 * @param {Object} options - The options object.
 * @param {string} options.roboId - The ID of the Robo device to disconnect from.
 */
const handleDisconnectFromRobo = (dispatch, { roboId }) => {
  addSentryBreadcrumb(`Disconnect from the MainBlock: ${roboId}`);
  const roboClient = roboClientManager.getClient(roboId);

  if (roboClient) {
    roboClient.disconnect();
    roboClientManager.removeClient(roboId);
  }

  dispatch(roboDisconnected());
};

/**
 * Initializes an MQTT client with the given URL, username, and password.
 * @returns {Promise<MQTTClient>} A promise that resolves with the MQTT client instance.
 */
export const initMqttClient = async bridgeId => {
  const url = `wss://${bridgeId ? bridgeId + '.' : ''}devices.robowunderkind.com:443/mqtt`;
  const username = 'ROBO_BRIDGE'; // fixme - move to config
  const password = '8DF78WUH23R'; // fixme - move to config

  const options = {
    url: url,
    username: username,
    password: password,
    clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
  };

  const connectionOptions = {
    reconnect: true,
    reconnectInterval: 5000,
    maxReconnectAttempts: 2,
    timeout: 10,
    keepAliveInterval: 5,
    cleanSession: true,
  };

  return new MQTTClient(options, connectionOptions);
};

/**
 * Parses a string of comma-separated robo names and their connection status into an array of objects.
 * @param {string} payloadString - The string to parse.
 * @returns {Array<{name: string, connected: boolean, alias: string, macAddress: string}>} - An array of objects containing the name and connection status of each robot.
 */
export const parseRoboList = payloadString => {
  return payloadString
    .split(',')
    .filter(roboName => !!roboName)
    .map(roboName => {
      const [alias, name, connected] = roboName.split(/[:|]/);
      return {
        alias,
        name,
        macAddress: extractMACAddressFromRoboName(name),
        connected: connected === '1',
      };
    });
};

export const extractMACAddressFromRoboName = name => {
  const match = /^RW_(RW-)?(?<MAC>\w+$)/.exec(name);

  if (match) {
    return match.groups.MAC;
  }
  return null;
};
