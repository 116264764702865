import { ElementEntity, ElementType } from '@webapp/store/types';
import { ModuleTypes } from '@lib/robo/types';
import { createElements, createElementsFromList } from '@webapp/store/state/utils/create-element';

const defaultToggles = {
  active: false,
  hidden: false,
  disabled: false,
  highlighted: false,
};

const elements: ElementEntity[] = [];

// Motion - Motor
for (const element of createElements(6, i => ({
  id: `live-motor-${i}`,
  name: 'Motor',
  type: 'motor',
  icon: 'MotorIcon',
  sectionId: 'live-motion-section',
  template: 'motion-element',
  counter: i,
  badge: `${i}`,
  requiredModules: [`${ModuleTypes.Motor}_${i}`],

  widgetType: 'motor-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Motion - Angle
for (const element of createElements(6, i => ({
  id: `live-angle-${i}`,
  name: 'Angle',
  type: 'angle',
  icon: 'AngleIcon',
  sectionId: 'live-motion-section',
  template: 'motion-element',
  counter: i,
  badge: `${i}`,
  requiredModules: [`${ModuleTypes.Motor}_${i}`],

  widgetType: 'motor-angle-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Motion - Joystick
for (const element of createElements(1, i => ({
  id: `live-joystick-${i}`,
  name: 'Joystick',
  type: 'joystick',
  icon: 'JoystickIcon',
  sectionId: 'live-motion-section',
  template: 'motion-element',
  counter: i,
  badge: false,

  requiredModuleTypes: [ModuleTypes.Motor, ModuleTypes.Motor],

  widgetType: 'joystick-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Visual - RGB Light
for (const element of createElements(6, i => ({
  id: `live-rgb-light-${i}`,
  name: 'Light',
  type: 'light',
  icon: 'RgbLightIcon',
  sectionId: 'live-visual-section',
  template: 'visual-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.LedRGB}_${i}`],

  widgetType: 'rgb-light-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Visual - Laughing
for (const element of createElements(4, i => ({
  id: `live-laughing-${i}`,
  name: 'Laughing',
  type: 'laughing',
  icon: 'LaughingFaceIcon',
  sectionId: 'live-visual-section',
  template: 'visual-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.LedDisplay}_${i}`],

  widgetType: 'laughing-face-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Visual - Smiling
for (const element of createElements(4, i => ({
  id: `live-smiling-${i}`,
  name: 'Smiling',
  type: 'smiling',
  icon: 'SmilingFaceIcon',
  sectionId: 'live-visual-section',
  template: 'visual-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.LedDisplay}_${i}`],

  widgetType: 'smiling-face-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Visual - Looking
for (const element of createElements(4, i => ({
  id: `live-looking-${i}`,
  name: 'Looking',
  type: 'looking',
  icon: 'LookingFaceIcon',
  sectionId: 'live-visual-section',
  template: 'visual-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.LedDisplay}_${i}`],

  widgetType: 'looking-face-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Visual - Led Matrix
for (const element of createElements(4, i => ({
  id: `live-led-matrix-${i}`,
  name: 'Paint',
  type: 'led-matrix',
  icon: 'LedDisplayIcon',
  sectionId: 'live-visual-section',
  template: 'visual-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.LedDisplay}_${i}`],

  widgetType: 'led-pixel-display-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sounds
for (const element of createElementsFromList(
  1,
  [
    {
      name: 'Party',
      type: 'party-sound',
      icon: 'PartySoundIcon',
    },
    {
      name: 'Bell',
      type: 'bell-sound',
      icon: 'BellSoundIcon',
    },
    {
      name: 'Car',
      type: 'car-sound',
      icon: 'CarSoundIcon',
    },
    {
      name: 'Chick',
      type: 'chick-sound',
      icon: 'ChickSoundIcon',
    },
    {
      name: 'Clock',
      type: 'clock-sound',
      icon: 'ClockSoundIcon',
    },
    {
      name: 'Cat',
      type: 'cat-sound',
      icon: 'CatSoundIcon',
    },
    {
      name: 'Dog',
      type: 'dog-sound',
      icon: 'DogSoundIcon',
    },
    {
      name: 'Robot',
      type: 'robot-sound',
      icon: 'RobotSoundIcon',
    },
    {
      name: 'Owl',
      type: 'owl-sound',
      icon: 'OwlSoundIcon',
    },
    {
      name: 'Spaceship',
      type: 'spaceship-sound',
      icon: 'SpaceshipSoundIcon',
    },
    {
      name: 'Dinosaur',
      type: 'dinosaur-sound',
      icon: 'DinosaurSoundIcon',
    },
    {
      name: 'Horse',
      type: 'horse-sound',
      icon: 'HorseSoundIcon',
    },
  ],
  (i, { name, type, icon }) => ({
    id: `live-${type}-${i}`,
    name: `${name}`,
    type: `${type}` as ElementType,
    icon: `${icon}`,
    sectionId: 'live-sound-section',
    template: 'sound-element',
    counter: i,
    badge: false,

    requiredModules: [`${ModuleTypes.System}_1`],

    widgetType: `live-sound-widget`,
    widgetsLimit: 1,

    ...defaultToggles,
  })
)) {
  elements.push(element);
}

// Sensing - Motion
for (const element of createElements(4, i => ({
  id: `live-motion-sensor-${i}`,
  name: 'Motion',
  type: 'motion-sensor',
  icon: 'MotionSensorIcon',
  sectionId: 'live-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.Motion}_${i}`],

  widgetType: 'motion-sensor-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Light
for (const element of createElements(4, i => ({
  id: `live-light-sensor-${i}`,
  name: 'Light',
  type: 'light-sensor',
  icon: 'LightSensorIcon',
  sectionId: 'live-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.LightSensor}_${i}`],

  widgetType: 'light-sensor-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Distance
for (const element of createElements(4, i => ({
  id: `live-distance-sensor-${i}`,
  name: 'Distance',
  type: 'distance-sensor',
  icon: 'DistanceSensorIcon',
  sectionId: 'live-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.Ultrasonic}_${i}`],

  widgetType: 'distance-sensor-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Line Follower
for (const element of createElements(4, i => ({
  id: `live-line-sensor-${i}`,
  name: 'Line',
  type: 'line-sensor',
  icon: 'LineFollowerSensorIcon',
  sectionId: 'live-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.Linetracker}_${i}`],

  widgetType: 'line-follower-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Accelerometer
for (const element of createElements(4, i => ({
  id: `live-gyro-sensor-${i}`,
  name: 'Accelerometer',
  type: 'gyro',
  icon: 'AccelerometerIcon',
  sectionId: 'live-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.Accelerometer}_${i}`],

  widgetType: 'accelerometer-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Sensing - Button
for (const element of createElements(4, i => ({
  id: `live-button-sensor-${i}`,
  name: 'Button',
  type: 'button',
  icon: 'ButtonOffSensorIcon',
  sectionId: 'live-sensing-section',
  template: 'sensing-element',
  counter: i,
  badge: `${i}`,

  requiredModules: [`${ModuleTypes.Button}_${i}`],

  widgetType: 'button-widget',
  widgetsLimit: 1,

  ...defaultToggles,
}))) {
  elements.push(element);
}

// Convert the array into the entities and ids structure
const liveElementsInitialState = {
  ids: elements.map(element => element.id),
  entities: elements.reduce(
    (entities, element) => {
      entities[element.id] = element;
      return entities;
    },
    {} as Record<string, ElementEntity>
  ),
};

export default liveElementsInitialState;
