import { useRef, useState } from 'react';
import { navigate } from 'gatsby';
import { useParams } from '@reach/router';

import { useTheme } from '@mui/material/styles';

import { Box, IconButton, TextField, Typography, ClickAwayListener } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

import { Button } from '@components/Button/button';

import {
  useCreateEditorProjectMutation,
  useLazyUserEditorProjectsCountQuery,
  useUpdateEditorProjectMutation,
} from '@webapp/store/api/editor-project.api.graphql';

import { Enum_Editorproject_Type } from '@store/gql/graphql';
import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useEditor } from '@webapp/hooks/use-editor-hook';
import { colors } from '@themes/config/theme-colors';

const CURRENT_EDITOR_PROJECT_VERSION = '1';

const NameProject: React.FunctionComponent = () => {
  const [editMode, setEditMode] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const { projectId } = useParams();

  const theme = useTheme();

  const [create, { isLoading: isCreatingProject }] = useCreateEditorProjectMutation();
  const [update, { isLoading: isUpdatingProject }] = useUpdateEditorProjectMutation();
  const [getUserEditorProjectsCount] = useLazyUserEditorProjectsCountQuery();

  const {
    editorType,
    currentEditorProject: editorProject,
    currentEditorProjectIsLoading: editorProjectIsLoading,
    currentEditorProjectRefetch: refetch,
  } = useEditorContext();

  const { state, prepareStateForSave } = useEditor(editorType!);

  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * Save the project
   */
  const handleSave = async ({ id, title }: { id: string; title: string }) => {
    setIsUpdating(true);

    await update({
      id: id,
      data: {
        title: title.trim(),
        editorState: prepareStateForSave(state),
      },
    });

    await refetch?.();
    setEditMode(false);
    setIsUpdating(false);
  };

  /**
   * Create a new project
   */
  const handleCreate = async () => {
    if (!editorType) return;

    const projectsCount = await getUserEditorProjectsCount().then(data => data?.data?.userEditorProjectsCount?.data);

    const newProject = await create({
      data: {
        title: `Project ${(projectsCount ?? 0) + 1}`,
        editorState: prepareStateForSave(state),
        version: CURRENT_EDITOR_PROJECT_VERSION,
        type: editorType as Enum_Editorproject_Type,
      },
    }).then(result => {
      if ('data' in result) {
        const project = result.data.createEditorProject?.data;
        if (!project) throw new Error('Project creation error');
        return project;
      }
      throw new Error('Project creation error');
    });

    navigate(`/webapp/${editorType}/${newProject.attributes?.uuid}`, { replace: true });
  };

  /**
   * Render the edit mode
   */
  const renderEditMode = () => (
    <ClickAwayListener onClickAway={() => setEditMode(false)}>
      <Box
        component="form"
        onSubmit={e => {
          e.preventDefault();

          if (!inputRef.current || !editorProject) {
            return;
          }

          handleSave({ id: editorProject.attributes.uuid, title: inputRef.current.value });
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '90px',
        }}
      >
        <TextField
          id=""
          size="large"
          defaultValue={editorProject.attributes.title}
          autoComplete={'off'}
          inputRef={inputRef}
          disabled={isUpdating}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          sx={{
            borderRadius: '8px',
            background: 'white',
            fieldset: {
              border: `2px solid ${colors.violet.main} !important`,
            },
          }}
        />

        <Box
          sx={{
            border: `2px solid ${colors.violet.main}`,
            borderTopRightRadius: '15px',
            borderBottomRightRadius: '15px',
            paddingLeft: '10px',
            marginLeft: '-10px',
            background: colors.black['050'],
            display: 'flex',
            flexWrap: 'no-wrap',
          }}
        >
          <IconButton color="primary" disabled={isUpdating} type="submit">
            <SaveAltIcon />
          </IconButton>

          <IconButton color="error" disabled={isUpdating} onClick={() => setEditMode(false)}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>
    </ClickAwayListener>
  );

  /**
   * Render the view mode
   */
  const renderViewMode = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '24px',
      }}
    >
      <IconButton
        sx={{
          background: colors.secondary.blocklyApp,
          color: colors.violet.main,
        }}
        onClick={() => handleSave({ id: editorProject.attributes.uuid, title: editorProject.attributes.title })}
      >
        <SaveIcon sx={{ width: '50px', height: '50px' }} />
      </IconButton>

      <Button
        variant="outlined"
        color="primary"
        size="large"
        sx={{
          maxWidth: '260px',

          [theme.breakpoints.down('md')]: {
            maxWidth: '200px',
          },
        }}
        endIcon={<ModeEditOutlineOutlinedIcon />}
        isLoading={editorProjectIsLoading || isUpdatingProject}
        onClick={() => setEditMode(true)}
      >
        <Typography
          variant="x-headline5-bold"
          color={colors.black['500']}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '250px',
          }}
        >
          {editorProject ? editorProject.attributes.title : 'Loading...'}
        </Typography>
      </Button>
    </Box>
  );

  /**
   * Render the create button
   */
  const renderCreateButton = () => (
    <Button
      variant="outlined"
      color="primary"
      size="large"
      sx={{
        marginTop: '90px',
        whiteSpace: 'nowrap',
        minWidth: '250px',

        [theme.breakpoints.down('md')]: {
          minWidth: '200px',
        },
      }}
      isLoading={isCreatingProject}
      onClick={handleCreate}
    >
      Save as new project
    </Button>
  );

  // If the project was not found, render the create button
  if (!projectId) {
    return renderCreateButton();
  }

  // If the project was found and the user is editing it's name, render the edit mode
  if (editMode && editorProject) {
    return renderEditMode();
  }

  // If the project was found and the user is viewing it's name, render the view mode
  return renderViewMode();
};

export default NameProject;
