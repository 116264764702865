import { Slider } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSlider = styled(Slider)(({ theme, thumb, border }) => ({
  color: '#fff',
  boxShadow: 'none',
  '& .MuiSlider-thumb': {
    backgroundColor: '#fff',
    width: thumb || '12px',
    height: thumb || '12px',
    border: border || 'none',
    '&$focusVisible': {
      boxShadow: 'none',
    },
    '&:focus, &:hover, &$active': {
      boxShadow: 'none',
    },
  },
  '& .MuiSlider-thumb:hover': {
    boxShadow: 'none',
  },

  '&:focus, &:hover, &$active': {
    boxShadow: 'none',
  },
  '&.Mui-disabled': {
    color: '#fff',
  },
  track: {
    boxShadow: 'none',
  },
  height: '60%',
  marginTop: '5px',
  marginBottom: '10px',
}));

export default StyledSlider;
