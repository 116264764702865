import { BaseModule } from './base-module';

export class Motion extends BaseModule {
  constructor(id, client, store) {
    super(id, client, 'motions', store);

    this.client.registerHandler(
      'onMotionDetector',
      ({ data }) => {
        this.updateDataState({
          sensorData: {
            motion: Motion.parsePayload(data),
          },
        });
      },
      id
    );
  }

  static parsePayload(data) {
    return data[0] === 1;
  }
}
