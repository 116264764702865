import { DEFAULT_COMMAND_QUEUE_INERVAL, RoboClient } from '../robo-client';
import { ModulesCollectionTypes } from '../types';
import { BaseModule, IRoboStore } from './base-module';

export class LedDisplay extends BaseModule<ReturnType<(typeof LedDisplay)['initialDataState']>> {
  static DisplaySize = 16;

  static TextMaxLength = 255;

  static Image = {
    Custom: 0xff,
    SmilingFace: 0x01,
    LaughingFace: 0x02,
  };

  static Animations = {
    LaughingFace: 0x00,
    SmilingFace: 0x01,
    LookingFace: 0x02,
    Custom: 0xff,
  };

  static ImageParts = {
    First: 0,
    Second: 1,
  };

  static InfiniteTime = 0xffff;

  static Rotation = {
    Degrees_0: 0,
    Degrees_90: 1,
    Degrees_180: 2,
    Degrees_270: 3,
  };

  constructor(id: string, client: RoboClient, store: IRoboStore) {
    super(id, client, ModulesCollectionTypes.LedDisplay, store);
  }

  /**
   * Display matrix on the LED display
   */
  setImage(matrix: boolean[][], rotation = LedDisplay.Rotation.Degrees_0) {
    const halves = LedDisplay.splitMatrixToHalvesAndToPayload(matrix);

    this.client.loadCustomImage(LedDisplay.ImageParts.First, this.index, halves.half1);
    this.client.loadCustomImage(LedDisplay.ImageParts.Second, this.index, halves.half2);
    this.client.setDisplayImage(0, this.index, LedDisplay.Image.Custom, rotation, LedDisplay.InfiniteTime);
  }

  setImageAction(
    matrix: boolean[][],
    rotation = LedDisplay.Rotation.Degrees_0,
    timeMs: number,
    onActionDone: ({ isError, targetId }: { isError: boolean; targetId: string }) => void
  ) {
    const actionId = this.generateActionOrTriggerId();
    this.subscribeToResponse(actionId, onActionDone);

    const halves = LedDisplay.splitMatrixToHalvesAndToPayload(matrix);

    this.client.loadCustomImage(LedDisplay.ImageParts.First, this.index, halves.half1);
    this.client.loadCustomImage(LedDisplay.ImageParts.Second, this.index, halves.half2);
    this.client.setDisplayImage(actionId, this.index, LedDisplay.Image.Custom, rotation, timeMs);

    return { actionId };
  }

  setAnimation(animation: number, orientation = LedDisplay.Rotation.Degrees_0) {
    this.client.setAnimation(0, this.index, animation, 0x01, 0x00, orientation, 0x00, 0x00);
  }

  async setAnimationAction(
    animation: number | Array<boolean[][]>,
    repeats: number,
    reverse: number,
    orientation: number,
    framerateMs: number,
    onActionDone: ({ isError, targetId }: { isError: boolean; targetId: string }) => void
  ) {
    const actionId = this.generateActionOrTriggerId();
    this.subscribeToResponse(actionId, onActionDone);

    if (typeof animation === 'number') {
      this.client.setAnimation(actionId, this.index, animation, repeats, reverse, orientation, 0x00, 0x00);
    } else {
      for (const frame of animation) {
        const halves = LedDisplay.splitMatrixToHalvesAndToPayload(frame);
        const frameIndex = animation.indexOf(frame);

        this.client.loadCustomAnimationFrame(LedDisplay.ImageParts.First, this.index, frameIndex, halves.half1);
        this.client.loadCustomAnimationFrame(LedDisplay.ImageParts.Second, this.index, frameIndex, halves.half2);
      }
      this.client.setAnimation(
        actionId,
        this.index,
        LedDisplay.Animations.Custom,
        repeats,
        reverse,
        orientation,
        animation.length,
        framerateMs
      );
    }
  }

  setTextAction(
    text: string,
    scrollingSpeedMs: number,
    rotation: number,
    onActionDone: ({ isError, targetId }: { isError: boolean; targetId: string }) => void
  ) {
    const actionId = this.generateActionOrTriggerId();
    this.subscribeToResponse(actionId, onActionDone);

    this.client.loadCustomText(this.index, text);
    this.client.setDisplayText(actionId, this.index, rotation, text.length, scrollingSpeedMs);
  }

  rotate(image: number, orientation: number) {
    this.client.setDisplayImage(1, this.index, image, orientation, LedDisplay.InfiniteTime);
  }

  stop() {
    this.client.displayStop(this.index);
  }

  static convertBooleanMatrixToPayload(matrix: boolean[][]) {
    const payload = new Uint8Array(matrix.length * 2);

    // Loop through each row to set the bits for 'on' pixels
    for (const row of matrix) {
      let rowMSB = 0;
      let rowLSB = 0;

      for (let i = 0; i < row.length; i++) {
        if (row[i]) {
          // If the pixel is 'on', set the bit at the correct position
          if (i < 8) {
            // If index is less than 8, it's in the MSB (most significant byte)
            rowMSB |= 1 << (7 - i);
          } else {
            // If index is 8 or more, it's in the LSB (the least significant byte)
            rowLSB |= 1 << (15 - i);
          }
        }
      }

      // Add the two bytes for the current row to the payload
      payload.set([rowMSB, rowLSB], matrix.indexOf(row) * 2);
    }

    return payload;
  }

  static splitMatrixToHalvesAndToPayload(matrix: boolean[][]) {
    return {
      half1: LedDisplay.convertBooleanMatrixToPayload(matrix.slice(0, 8)),
      half2: LedDisplay.convertBooleanMatrixToPayload(matrix.slice(8)),
    };
  }

  static convertAngleToOrientation(angle: number) {
    if (angle === 0) {
      return LedDisplay.Rotation.Degrees_0;
    } else if (angle === 90) {
      return LedDisplay.Rotation.Degrees_90;
    } else if (angle === 180) {
      return LedDisplay.Rotation.Degrees_180;
    } else if (angle === 270) {
      return LedDisplay.Rotation.Degrees_270;
    } else {
      throw new Error('Invalid angle. It should be 0, 90, 180, or 270.');
    }
  }
}
