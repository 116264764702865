import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import {
  CodeEditorState,
  SetCodeEditorStatePayload,
  WidgetId,
  WidgetData,
  WidgetProps,
  CodeGroupId,
  CodeActionId,
  CodeEdgeId,
  CodeLabelId,
  AddWidgetPayload,
  UpdateWidgetPayload,
  UpdateWidgetDataPayload,
  UpdateWidgetPropsPayload,
  CodeEditorAddGroupPayload,
  CodeEditorUpdateGroupPayload,
  CodeEditorAddActionPayload,
  CodeEditorUpdateActionPayload,
  CodeEditorAddEdgePayload,
  CodeEditorUpdateEdgePayload,
  CodeEditorAddLabelPayload,
  CodeEditorUpdateLabelPayload,
  CodeEditorAddTriggerPayload,
  CodeEditorUpdateTriggerPayload,
  CodeEditorMode,
  CodeEditorTrashCanPosition,
} from '@webapp/store/types';

const initialState: CodeEditorState = {
  labels: {},
  triggers: {},
  widgets: {},
  groups: {},
  actions: {},
  edges: {},

  runtime: {
    mode: CodeEditorMode.BUILD,
    isPlaying: false,
    playButtonIsBlocked: false,
    zoomIsEnabled: true,
    connectGroupsIsEnabled: true,
    recordSensorsIsEnabled: true,
    trashCanPosition: 'left',
  },
};

// todo Think on ability of mocking initial state
// import { initialState } from '@webapp/components/editors/robo-code/config/mock-state';

const editorSlice = createSlice({
  name: 'code/editor',
  initialState,
  reducers: {
    /** Set the editor state */
    setEditorState: (state, action: PayloadAction<SetCodeEditorStatePayload>) => {
      const { widgets, groups, actions, edges, labels, triggers } = action.payload;

      state.widgets = widgets ?? {};
      state.groups = groups ?? {};
      state.actions = actions ?? {};
      state.edges = edges ?? {};
      state.labels = labels ?? {};
      state.triggers = triggers ?? {};
    },

    /** Reset the editor state */
    resetEditorState: state => {
      Object.assign(state, initialState);
    },

    /** Add widget to the state */
    addWidget: (state, action: PayloadAction<AddWidgetPayload>) => {
      const { id, widget } = action.payload;
      state.widgets[id] = widget;
    },

    /** Update an existing widget in the state */
    updateWidget: (state, action: PayloadAction<UpdateWidgetPayload>) => {
      const { id, widget } = action.payload;

      if (state.widgets[id]) {
        state.widgets[id] = { ...state.widgets[id], ...widget };
      }
    },

    /** Remove a widget from the state */
    removeWidget: (state, action: PayloadAction<WidgetId>) => {
      const id = action.payload;
      delete state.widgets[id];
    },

    /** Remove all widgets from the state */
    removeAllWidgets: state => {
      state.widgets = {};
    },

    /** Update the data of a widget in the state */
    updateWidgetData: (state, action: PayloadAction<UpdateWidgetDataPayload>) => {
      const { id, data } = action.payload;
      const widget = state.widgets[id];

      if (widget) {
        widget.data = { ...widget.data, ...data } as WidgetData;
      }
    },

    /** Update the properties of a widget in the state */
    updateWidgetProps: (state, action: PayloadAction<UpdateWidgetPropsPayload>) => {
      const { id, props } = action.payload;
      const widget = state.widgets[id];

      if (widget) {
        widget.props = { ...widget.props, ...props } as WidgetProps;
      }
    },

    /** Add a group to the state */
    addGroup: (state, action: PayloadAction<CodeEditorAddGroupPayload>) => {
      const { id, group } = action.payload;
      group.actionIds = group.actionIds || [];
      group.edgeIds = group.edgeIds || [];
      group.triggerIds = group.triggerIds || [];
      group.labelIds = group.labelIds || [];
      state.groups[id] = group;
    },

    /** Update a group in the state */
    updateGroup: (state, action: PayloadAction<CodeEditorUpdateGroupPayload>) => {
      const { id, group } = action.payload;

      if (state.groups[id]) {
        state.groups[id] = { ...state.groups[id], ...group };
      }
    },

    /** Remove a group from the state */
    removeGroup: (state, action: PayloadAction<CodeGroupId>) => {
      const id = action.payload;
      delete state.groups[id];
    },

    /** Add an action to the state */
    addAction: (state, action: PayloadAction<CodeEditorAddActionPayload>) => {
      const { id, action: codeAction } = action.payload;
      state.actions[id] = codeAction;
    },

    /** Update an action in the state */
    updateAction: (state, action: PayloadAction<CodeEditorUpdateActionPayload>) => {
      const { id, action: codeAction } = action.payload;

      if (state.actions[id]) {
        state.actions[id] = { ...state.actions[id], ...codeAction };
      }
    },

    /** Remove an action from the state */
    removeAction: (state, action: PayloadAction<CodeActionId>) => {
      const id = action.payload;
      delete state.actions[id];
    },

    /** Add an edge to the state */
    addEdge: (state, action: PayloadAction<CodeEditorAddEdgePayload>) => {
      const { id, edge } = action.payload;
      state.edges[id] = edge;
    },

    /** Update an edge in the state */
    updateEdge: (state, action: PayloadAction<CodeEditorUpdateEdgePayload>) => {
      const { id, edge } = action.payload;

      if (state.edges[id]) {
        state.edges[id] = { ...state.edges[id], ...edge };
      }
    },

    /** Remove an edge from the state */
    removeEdge: (state, action: PayloadAction<CodeEdgeId>) => {
      const id = action.payload;
      delete state.edges[id];
    },

    /** Add a label to the state */
    addLabel: (state, action: PayloadAction<CodeEditorAddLabelPayload>) => {
      const { id, label } = action.payload;
      state.labels[id] = label;
    },

    /** Update a label in the state */
    updateLabel: (state, action: PayloadAction<CodeEditorUpdateLabelPayload>) => {
      const { id, label } = action.payload;

      if (state.labels[id]) {
        state.labels[id] = { ...state.labels[id], ...label };
      }
    },

    /** Remove a label from the state */
    removeLabel: (state, action: PayloadAction<CodeLabelId>) => {
      const id = action.payload;
      delete state.labels[id];
    },

    /** Add a trigger to the state */
    addTrigger: (state, action: PayloadAction<CodeEditorAddTriggerPayload>) => {
      const { id, trigger } = action.payload;
      state.triggers[id] = trigger;
    },

    /** Update a trigger in the state */
    updateTrigger: (state, action: PayloadAction<CodeEditorUpdateTriggerPayload>) => {
      const { id, trigger } = action.payload;

      if (state.triggers[id]) {
        state.triggers[id] = { ...state.triggers[id], ...trigger };
      }
    },

    /** Remove a trigger from the state */
    removeTrigger: (state, action: PayloadAction<CodeLabelId>) => {
      const id = action.payload;
      delete state.triggers[id];
    },

    setIsPlaying: (state, action: PayloadAction<boolean>) => {
      state.runtime.isPlaying = action.payload;
    },

    setPlayButtonIsBlocked: (state, action: PayloadAction<boolean>) => {
      state.runtime.playButtonIsBlocked = action.payload;
    },

    setZoomIsEnabled: (state, action: PayloadAction<boolean>) => {
      state.runtime.zoomIsEnabled = action.payload;
    },

    setConnectGroupsIsEnabled: (state, action: PayloadAction<boolean>) => {
      state.runtime.connectGroupsIsEnabled = action.payload;
    },

    setRecordSensorsIsEnabled: (state, action: PayloadAction<boolean>) => {
      state.runtime.recordSensorsIsEnabled = action.payload;
    },

    setMode: (state, action: PayloadAction<CodeEditorMode>) => {
      state.runtime.mode = action.payload;
    },

    setTrashCanPosition: (state, action: PayloadAction<CodeEditorTrashCanPosition>) => {
      state.runtime.trashCanPosition = action.payload;
    },
  },
});

export const {
  setEditorState,
  resetEditorState,
  addWidget,
  removeWidget,
  removeAllWidgets,
  updateWidget,
  updateWidgetData,
  updateWidgetProps,
  addGroup,
  updateGroup,
  removeGroup,
  addEdge,
  updateEdge,
  removeEdge,
  addAction,
  updateAction,
  removeAction,
  addLabel,
  updateLabel,
  removeLabel,
  addTrigger,
  updateTrigger,
  removeTrigger,
  setIsPlaying,
  setPlayButtonIsBlocked,
  setZoomIsEnabled,
  setConnectGroupsIsEnabled,
  setRecordSensorsIsEnabled,
  setMode,
  setTrashCanPosition,
} = editorSlice.actions;

export default editorSlice.reducer;

export const editorStateSelector = createSelector(
  (state: CodeEditorState) => state,
  state => state
);

export const widgetsSelector = createSelector([editorStateSelector], state => state.widgets);

export const widgetSelector = createSelector(
  [widgetsSelector, (_: CodeEditorState, widgetId: WidgetId) => widgetId],
  (widgets, widgetId) => widgets[widgetId]
);

export const groupsSelector = createSelector([editorStateSelector], state => state.groups);

export const groupSelector = createSelector(
  [groupsSelector, (_: CodeEditorState, groupId: CodeGroupId) => groupId],
  (groups, groupId) => groups[groupId]
);

export const actionsSelector = createSelector([editorStateSelector], state => state.actions);

export const actionSelector = createSelector(
  [actionsSelector, (_: CodeEditorState, actionId: CodeActionId) => actionId],
  (actions, actionId) => actions[actionId]
);

export const edgesSelector = createSelector([editorStateSelector], state => state.edges);

export const edgeSelector = createSelector(
  [edgesSelector, (_: CodeEditorState, edgeId: CodeEdgeId) => edgeId],
  (edges, edgeId) => edges[edgeId]
);

export const labelsSelector = createSelector([editorStateSelector], state => state.labels);

export const labelSelector = createSelector(
  [labelsSelector, (_: CodeEditorState, labelId: CodeLabelId) => labelId],
  (labels, labelId) => labels[labelId]
);

export const triggersSelector = createSelector([editorStateSelector], state => state.triggers);

export const triggerSelector = createSelector(
  [triggersSelector, (_: CodeEditorState, triggerId: CodeLabelId) => triggerId],
  (triggers, triggerId) => triggers[triggerId]
);

export const actionsByGroupIdSelector = createSelector(
  [actionsSelector, (_: CodeEditorState, groupId: CodeGroupId) => groupId],
  (actions, groupId) => Object.values(actions).filter(action => action.groupId === groupId)
);

export const labelsByGroupIdSelector = createSelector(
  [labelsSelector, (_: CodeEditorState, groupId: CodeGroupId) => groupId],
  (labels, groupId) => Object.values(labels).filter(label => label.groupId === groupId)
);
export const triggersByGroupIdSelector = createSelector(
  [triggersSelector, (_: CodeEditorState, groupId: CodeGroupId) => groupId],
  (triggers, groupId) => Object.values(triggers).filter(trigger => trigger.groupId === groupId)
);

export const triggersByEdgeIdSelector = createSelector(
  [triggersSelector, (_: CodeEditorState, edgeId: CodeEdgeId) => edgeId],
  (triggers, edgeId) => Object.values(triggers).filter(trigger => trigger.edgeId === edgeId)
);

export const allEdgesByGroupIdSelector = createSelector(
  [edgesSelector, (_: CodeEditorState, groupId: CodeGroupId) => groupId],
  (edges, groupId) =>
    Object.values(edges).filter(edge => edge.sourceGroupId === groupId || edge.targetGroupId === groupId)
);

export const outgoingEdgesByGroupIdSelector = createSelector(
  [edgesSelector, (_: CodeEditorState, groupId: CodeGroupId) => groupId],
  (edges, groupId) => Object.values(edges).filter(edge => edge.sourceGroupId === groupId)
);

export const incomingEdgesByGroupIdSelector = createSelector(
  [edgesSelector, (_: CodeEditorState, groupId: CodeGroupId) => groupId],
  (edges, groupId) => Object.values(edges).filter(edge => edge.targetGroupId === groupId)
);

// Get all edges between two groups
export const edgesBetweenGroupsSelector = createSelector(
  [
    edgesSelector,
    (_: CodeEditorState, firstGroupId: CodeGroupId, secondGroupId: CodeGroupId) => ({ firstGroupId, secondGroupId }),
  ],
  (edges, { firstGroupId, secondGroupId }) =>
    Object.values(edges).filter(
      edge =>
        (edge.sourceGroupId === firstGroupId && edge.targetGroupId === secondGroupId) ||
        (edge.sourceGroupId === secondGroupId && edge.targetGroupId === firstGroupId)
    )
);
