import { useState } from 'react';

import { Box, Grid, TextareaAutosize } from '@mui/material';

import { useSelector } from '@store/configureStore';

import { useEditorContext } from '@webapp/components/hoc/with-editor';
import { useRobo } from '@webapp/hooks/use-robo-hook';
import useCodeEditor from '@webapp/components/editors/robo-code/hooks/use-code-editor-hook';

import { elementsSelectors } from '@webapp/store/slices/code/elements.slice';

import { WidgetId } from '@webapp/store/types';

const MessageWidget = ({ id }: { id: WidgetId }) => {
  const { isPlaying } = useEditorContext();

  const { getWidgetById, updateWidgetData } = useCodeEditor();

  // get related widget
  const widget = getWidgetById(id);

  const { model: roboModel } = useRobo();
  const { data } = widget;

  const [message, setMessage] = useState<string>(data.message);

  const elementEntity = useSelector(state => elementsSelectors.selectElementById(state, data.elementId));

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
    updateWidgetData(id, { message: newMessage });
  };

  return (
    <Box sx={{ width: '320px', height: '265px', display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TextareaAutosize onChange={handleMessageChange} style={{ width: '100%' }} minRows={2} maxLength={256}>
            {message}
          </TextareaAutosize>
        </Grid>
      </Grid>
    </Box>
  );
};

MessageWidget.initialData = {
  message: '',
};

export default MessageWidget;
