import React, { useContext, useState } from 'react';

import { Joystick } from 'react-joystick-component';

import { EditorContext } from '@webapp/components/hoc/with-editor';
import StyledBox from '@webapp/components/blocks/component/styled-box';
import { WidgetSettingsIcon } from '@webapp/components/icons';

import { useRobo } from '@webapp/hooks/use-robo-hook';
import { useEditor } from '@webapp/hooks/use-editor-hook';

import SettingsModal from './joystick-components/joystick-settings-modal';
import SettingsButton from './joystick-components/settings-button';
import JoystickBase from './joystick-components/joystick-base';
import JoystickHandle from './joystick-components/joystick-handle';

import { MODULE_MAPPINGS } from '@lib/robo/module-mappings';
import { ROTATION_DIRECTIONS } from '@lib/robo/modules/motor';
import { WidgetColors } from '@webapp/components/blocks/widgets/constants';

export const ALIGNMENT = {
  left: 'left',
  right: 'right',
  none: 'none',
};

const JoystickWidget = ({ data, disabled }) => {
  const { id } = data;
  const { updateWidgetData } = useEditor('live');

  const { isPlaying } = useContext(EditorContext);
  const {
    model,
    store: { model: store },
    connected,
  } = useRobo();

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const connectedMotorIds = Object.keys(store?.motors || {});
  const connectedMotorsState = store?.motors;
  const connectedMotorsModules = model?.motors;

  /**
   * Handles the change of the motor alignment.
   * @param {string} motorId - The id of the motor to change the alignment of.
   * @param {string} alignment - The new alignment of the motor.
   */
  const handleMotorAlignmentChange = (motorId, alignment) => {
    const newMotorsConfig = {
      ...data.motorsConfig,
      [motorId]: {
        ...data.motorsConfig[motorId],
        alignment,
      },
    };

    updateWidgetData(id, { motorsConfig: newMotorsConfig });
  };

  /**
   * Handles the change of the "isReversed" property.
   * @param {boolean} isReversed - The new value of the "isReversed" property.
   */
  const handleIsReversedChange = isReversed => {
    handleWidgetDataChange('isReversed', isReversed);
  };

  /**
   * Handles the change of the widget data.
   * @param {string} key - The key of the property to change.
   * @param {any} value - The new value of the property.
   */
  const handleWidgetDataChange = (key, value) => {
    updateWidgetData(id, { [key]: value });
  };

  /**
   * Handles the probe of a motor.
   * @param {string} motorId - The id of the motor to probe.
   */
  const handleMotorProbe = motorId => {
    const MOTOR = connectedMotorsModules[motorId];
    const probeAngle = 120;

    const configuredMotorAlignment = data.motorsConfig[motorId].alignment;
    const isReversed = data.isReversed;

    let rotationDirection = ROTATION_DIRECTIONS.cw;

    switch (configuredMotorAlignment) {
      case ALIGNMENT.left:
        rotationDirection = isReversed ? ROTATION_DIRECTIONS.cw : ROTATION_DIRECTIONS.ccw;
        break;
      case ALIGNMENT.right:
        rotationDirection = isReversed ? ROTATION_DIRECTIONS.ccw : ROTATION_DIRECTIONS.cw;
        break;
      case ALIGNMENT.none:
        rotationDirection = ROTATION_DIRECTIONS.cw;
        break;
    }

    MOTOR.angle(probeAngle, rotationDirection);
  };

  /**
   * Handles the start of interaction with the settings.
   * @param {Event} event - The event object.
   */
  const handleSettingsInteractionStart = event => {
    if (!connected || disabled) return;

    // prevent the editor from dragging the widget
    event.stopPropagation();
  };

  /**
   * Handles the click event for the settings button.
   * @param {Event} event - The click event.
   */
  const handleSettingsClick = event => {
    if (!connected || disabled) return;

    event.stopPropagation();

    setShowSettingsModal(true);
  };

  /**
   * Handles the movement of the joystick.
   *
   * @param {number} value - The value representing the movement of the joystick.
   */

  const handleJoystickMove = value => {
    const { x, y } = value;

    let L = x + y;
    let R = x - y;

    R *= -1;

    // Reverse motor direction if needed
    const isReversed = data.isReversed;

    // Clamp the values between -1 and 1
    L = Math.max(-1, Math.min(L, 1));
    R = Math.max(-1, Math.min(R, 1));

    connectedMotorIds.forEach(motorId => {
      const MOTOR = connectedMotorsModules[motorId];
      const configuredMotorAlignment = data.motorsConfig[motorId].alignment;

      let speed = 0;
      let rotationDirection = ROTATION_DIRECTIONS.cw;

      if (configuredMotorAlignment === ALIGNMENT.left) {
        speed = Math.round(L * 100);
        rotationDirection = isReversed ? ROTATION_DIRECTIONS.cw : ROTATION_DIRECTIONS.ccw;
      } else if (configuredMotorAlignment === ALIGNMENT.right) {
        speed = Math.round(R * 100);
        rotationDirection = isReversed ? ROTATION_DIRECTIONS.ccw : ROTATION_DIRECTIONS.cw;
      }

      if (speed === 0) {
        // stop motor immediately
        MOTOR?.setSpeed(0);
      } else {
        MOTOR?.setSpeedDebounced(speed, rotationDirection);
      }
    });
  };

  return (
    <>
      <StyledBox color={disabled ? WidgetColors.disabledBackground : '#156BFB'}>
        <Joystick
          size={155}
          sticky={false}
          baseImage={`data:image/svg+xml;base64,${btoa(JoystickBase)}`}
          stickImage={`data:image/svg+xml;base64,${btoa(JoystickHandle)}`}
          stickSize={80}
          throttle={50}
          stop={() => handleJoystickMove({ x: 0, y: 0 })}
          move={handleJoystickMove}
          disabled={!isPlaying || disabled}
        />

        <SettingsButton
          onClick={handleSettingsClick}
          onMouseDown={handleSettingsInteractionStart}
          onTouchStart={handleSettingsInteractionStart}
        >
          <WidgetSettingsIcon />
        </SettingsButton>
      </StyledBox>

      <SettingsModal
        open={showSettingsModal}
        dense
        onClose={() => setShowSettingsModal(false)}
        showCancelButton={false}
        showSubmitButton={false}
        showCloseButton={false}
        isReversed={data.isReversed}
        setIsReversed={handleIsReversedChange}
        setMotorAlignment={handleMotorAlignmentChange}
        motorsState={connectedMotorsState}
        motorsConfig={data.motorsConfig}
        probeMotor={handleMotorProbe}
      />
    </>
  );
};
//isReversed, setIsReversed, setMotorAlignment, motorsState, motorsConfig, probeMotor
JoystickWidget.initialProps = {
  width: 2,
  height: 2,
};

JoystickWidget.initialData = {
  isReversed: false,
  // generate initial motors config
  motorsConfig: MODULE_MAPPINGS.motors.modulePositions.reduce((acc, _, index) => {
    const motorId = `${MODULE_MAPPINGS.motors.idPrefix}${index + 1}`;
    const isEvenModule = (index + 1) % 2 === 0;
    acc[motorId] = { alignment: isEvenModule ? ALIGNMENT.right : ALIGNMENT.left };
    return acc;
  }, {}),
};

export default JoystickWidget;
